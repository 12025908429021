import React from 'react'
import style from "../../CSS/Services/SPP4Commitment.module.css"
import "../../CSS/Root.css"
import Heading from '../Heading'
import tickIcon from "../../Media/tickIcon.png"

const data = [
    {
        id:1,
        title:"Comprehensive Security",
        description:"You can trust that your data is safe and secure with our ISO 27001 certification and best practices in security and data protection.",
        icon:tickIcon
    },
    {
        id:2,
        title:"High-Quality Assurance",
        description:"You get piece-of-mind with our QA processes that adhere to the highest standards for delivering enterprise-grade software products.",
        icon:tickIcon
    },
    {
        id:3,
        title:"Expertise and Flexibility",
        description:"You can count on us to quickly adjust to changes in your project needs and provide engineering talent with the required skills.",
        icon:tickIcon
    },
]

const SPP4Commitment = () => {
  return <div id={style.SPP4Commitment}>
    <div>
        <Heading
            badge={"Commitment"}
            heading={"Our Commitments to you"}
        />
        <div className='flex'>
            {
                data?.map((e)=>{
                    return <div key={e?.id} className={style.commitmentCard}>
                        <img src={e?.icon} alt="" />
                        <h2>{e?.title}</h2>
                        <p>{e.description}</p>
                    </div>
                })
            }
        </div>
    </div>
  </div>
}

export default SPP4Commitment
