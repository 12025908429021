import React, { useEffect } from 'react'
import CPP1Hero from '../Components/ContactPage/CPP1-Hero'
import CPP2Help from '../Components/ContactPage/CPP2-Help'
import CPP3Appointment from '../Components/ContactPage/CPP3-Appointment'
import CPP4Booking from '../Components/ContactPage/CPP4-Booking'
import { useLocation } from 'react-router-dom'

const ContactPage = () => {

  const location = useLocation();


  useEffect(()=>{ 
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  },[location.pathname])

  return <div>
    <CPP1Hero/>
    <CPP2Help/>
    <CPP3Appointment/>
    <CPP4Booking/>
  </div>
}

export default ContactPage
