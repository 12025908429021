import { useLocation } from 'react-router-dom';
import './App.css';
import NavBar from './Components/NavBar';
import Routers from './Routers/Routers';
import Footer from './Components/Footer';
import { useEffect, useRef } from 'react';
import MenuBar from './Components/MenuBar';

function App() {

  const location = useLocation();


  useEffect(()=>{ 
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  },[location.pathname])

  
  

  return (

    


    <div className="App">
      {/* {
        location.pathname==="/"?"":<NavBar/>
      } */}
      <NavBar/>
      <Routers/>
      <Footer/>
      {/* {
        location.pathname==="/"?"":<Footer/>
      } */}
    </div>
  );
}

export default App;
