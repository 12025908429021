import { Link, useNavigate } from 'react-router-dom'
import style from '../CSS/MenuBar.module.css'
import "../CSS/Root.css"
import BasicButton from './BasicButton'

const LINKS = [
  {
      id:1,
      title:"Services",
      path:"/services"
  },
  {
      id:2,
      title:"How We Work",
      path:"/how-we-work"
  },
  {
      id:3,
      title:"Blogs",
      path:"/"
  },
  {
      id:4,
      title:"Careers",
      path:"/careers"
  },
  {
      id:5,
      title:"About",
      path:"/about"
  },
]

const MenuBar = ({menuOpen}) => {

  const Navigate = useNavigate()


  return <div id={style.MenuBar} className='flex' style={menuOpen?{height:"200px",padding:"20px 0px"}:{height:"0px",padding:"0px"}}>
    {
      LINKS?.map((e)=>{
        return <>
          <Link to={e?.path} className={style.links} style={menuOpen?{display:"block"}:{display:"none"}}>{e?.title}</Link>
        </>

      })
    }
    <BasicButton title={"Contact Us"} HandleButnClick={()=>Navigate("/contact")} style={menuOpen?{display:"block"}:{display:"none"}}/>
  </div>
}

export default MenuBar
