import React from 'react'
import "../../CSS/Root.css"
import style from "../../CSS/HowWeWork/HWP3Preparation.module.css"
import Heading from '../Heading'
import { IoRocketSharp, IoPeople, IoPieChart } from "react-icons/io5";
import { PiBuildingsFill } from "react-icons/pi";
import { FaRecycle } from "react-icons/fa6";
import { RiTeamFill } from "react-icons/ri";
import { MdLockClock } from "react-icons/md";


const gridData = [
  {
    id:1,
    icon:<PiBuildingsFill/>,
    title:"Intro call with a client"
  },
  {
    id:2,
    icon:<IoRocketSharp/>,
    title:"Signing NDA & getting project info"
  },
  {
    id:3,
    icon:<IoPeople/>,
    title:"Documents Review and Q&A"
  },
  {
    id:4,
    icon:<MdLockClock/>,
    title:"Dev proposal  & rough estimates"
  },
  {
    id:5,
    icon:<IoPieChart/>,
    title:"A call to discuss proposal"
  },
  {
    id:6,
    icon:<FaRecycle/>,
    title:"Signing a contract"
  },
  {
    id:7,
    icon:<RiTeamFill/>,
    title:"Build a team & start dev process"
  }
]


const HWP3Preparation = () => {
  return <div id={style.HWP3Preparation}>

      <div>
        <Heading
          badge={"INITIAL PHASE"}
          heading={"PREPARATION"}
          description={"This phase is mostly about communication and includes calls, personal meetings, and first estimations. While preparing we get information from the client about project goals and software requirement specification. Steps of Preparations."}
        />

        <div id={style.gridView} > 
          <div className={style.row}>
            {
              gridData?.map((e)=>{
                return e?.id<=4 ? <div className={`${style.col} flex`}>
                  <div className={`${style.gridCard} flex`}>
                    <div className='flex'>{e?.icon}</div>
                    <div className='flex'>{e?.title}</div>
                    <div></div>
                  </div>
                </div>
                :
                ""
              })
            }
          </div>
          <div className={`${style.row} ${style.rowEx}`}>
            {
              gridData?.map((e)=>{
                return e?.id>4 ? <div className={`${style.col} flex`}>
                  <div className={`${style.gridCard} flex`}>
                    <div className='flex'>{e?.icon}</div>
                    <div className='flex'>{e?.title}</div>
                    <div></div>      
                  </div>
                </div>
                :
                ""
              })
            }
          </div>
        </div>


        <div id={style.prepBottom}>
        Project documentation provided by Client: specifications, wireframes, hypothesis, design, etc. (if there is nothing to provide, we also can conduct a pre-project stage for you)
        </div>

      </div>

  </div>
}

export default HWP3Preparation;




// {
//   gridData?.map((e)=>{
//     return <>
//       <div>
//         <div className='flex'><IoRocketSharp/></div>
//         <div>Signing NDA & getting project info</div>
//         <div></div>
//       </div>
//     </>
//   })
// }