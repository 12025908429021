import React, { useEffect } from 'react'
import APP1Hero from '../Components/AboutPage/APP1-Hero'
import APP2About from '../Components/AboutPage/APP2-About'
import APP3Values from '../Components/AboutPage/APP3-Values'
import APP4Mission from '../Components/AboutPage/APP4-Mission'
import APP5Challenge from '../Components/AboutPage/APP5-Challenge'
import APP6Contact from '../Components/AboutPage/APP6-Contact'
import { useLocation } from 'react-router-dom'

const AboutPage = () => {

  const location = useLocation();


  useEffect(()=>{ 
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  },[location.pathname])

  return <div>
    <APP1Hero/>
    <APP2About/>
    <APP3Values/>
    <APP4Mission/>
    <APP5Challenge/>
    <APP6Contact/>
  </div>
}

export default AboutPage
