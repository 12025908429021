import React from 'react'
import "../../CSS/Root.css"
import style from "../../CSS/HowWeWork/HWP1Hero.module.css"
import BasicButton from '../BasicButton'
import heroImage from "../../Media/how-we-work-Hero.png"
import dotsPattern from "../../Media/DotOrnament.png"

const HWP1Hero = () => {
  return <div id={style.HWP1Hero} className='flex'>
    <div id={style.Heroleft}>
        <h1>What does working with lanzz look like?</h1>
        <p>We are a Team of Passionate Product Engineers Who work With you at every step of your Product Journey. Our Agile and Customer-Centric Approach Ensures your digital products get to Market Faster for a Competitive Edge.</p>
        <BasicButton
          title={"Contact  Now"}
          HandleButnClick={()=>document.querySelector(".howWorkContact").scrollIntoView({behavior:"smooth"})}
        />
        <img src={dotsPattern} alt="" />
    </div>

    <div id={style.HeroRight}>
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>
}

export default HWP1Hero
