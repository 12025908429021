import React from 'react'
import "../../CSS/Root.css"
import style from "../../CSS/HowWeWork/HWP2Work.module.css"
import dotsPattern from "../../Media/DotOrnament.png"
import cubeSearch from "../../Media/searchCube.svg"
import codeBox from "../../Media/codeBox.svg"
import wallet from "../../Media/wallet.svg"
import chartBar from "../../Media/chartBars.svg"


const gridData = [
    {
        id:1,
        icon:cubeSearch,
        iconColor:"#2639ED",
        iconBG:"#F1F7FF",
        title:"Risk Awareness",
        desc:"Acknowledge development partner risks."
    },
    {
        id:2,
        icon:codeBox,
        iconColor:"#FF007A",
        iconBG:"#FFF2F8",
        title:"Tailored Engagement",
        desc:"Customized solutions for your business."
    },
    {
        id:3,
        icon:wallet,
        iconColor:"#FF9900",
        iconBG:"#FFF7E3",
        title:"Financial Ease",
        desc:"Minimal client financial commitment."
    },
    {
        id:4,
        icon:chartBar,
        iconColor:"#00DA71",
        iconBG:"#DEFFEE",
        title:"Trust Building",
        desc:"Swiftly earn client trust."
    }
]


const HWP2Work = () => {
  return <div id={style.HWP2Work}>
    <div id={style.main} className='flex'>

    <div>
        <h1>How We Work</h1>
        <p>We mitigate risk, build trust, and ensure tailored solutions promptly.</p>
    </div>

    <div id={style.GridList}>
        <div></div>

        {
            gridData?.map((e)=>{
                return <div className={`${style.ListCard} flex`}>
                    <div 
                        className={`${style.cardIcon} flex`}
                        style={{
                            color:e?.iconColor,
                            backgroundColor:e?.iconBG
                        }}
                    >
                        <img src={e?.icon} alt="" />
                    </div>
                    <h4>{e?.title}</h4>
                    <p>{e?.desc}</p>
                </div>
            })
        }

        <div></div>
    </div>

    </div>

    <div id={style.LeftSVG}>

    </div>
    <div id={style.RightSVG}>

    </div>
    <img id={style.DotsPattern} src={dotsPattern} alt="" />
    <div id={style.PinkRing}>

    </div>
  </div>
}

export default HWP2Work
