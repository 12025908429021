import React from 'react'
import style from "../../CSS/Blogs/BPP4StayConnected.module.css"
import "../../CSS/Root.css"
import Heading from '../Heading'
import BasicButton from '../BasicButton'
import { HiOutlineMailOpen } from "react-icons/hi";
import { TbPhoneCall } from "react-icons/tb";
import { GrLocation } from "react-icons/gr";
import { IoShareSocialOutline } from "react-icons/io5";
import { FaLinkedinIn,FaFacebookF,FaTwitter,FaInstagram } from "react-icons/fa6";
import { Link, useNavigate } from 'react-router-dom'


const connectedData = [
  {
    id:1,
    icon:<HiOutlineMailOpen/>,
    title:"Email",
    content:"contact@lanzztech.com"
  },
  {
    id:2,
    icon:<TbPhoneCall/>,
    title:"Phone",
    content:"+91-843-00-000"
  },
  {
    id:3,
    icon:<GrLocation/>,
    title:"Office",
    content:"Pune, Maharashtra - 440019"
  },
  {
    id:4,
    icon:<IoShareSocialOutline/>,
    title:"Social",
  },
]


const socialContent =[
  {
    id:1,
    icon:<FaFacebookF/>,
    path:"https://m.facebook.com/profile.php?id=100090838940575"
  },
  {
    id:2,
    icon:<FaTwitter/>,
    path:"https://twitter.com/lanzz_tech"
  },
  {
    id:3,
    icon:<FaInstagram/>,
    path:"https://www.instagram.com/lanzztech/"
  },
  {
    id:4,
    icon:<FaLinkedinIn/>,
    path:"https://www.linkedin.com/company/lanzztech"
  }
]

const BPP4StayConnected = () => {

  const navigate = useNavigate();

  return <div id={style.BPP4StayConnected}>
    <div id={style.BPP4StayConnectedTop} className='flex'>
      <div className='flex'>
        <Heading
          badge={"CONTACT"}
          heading={"Let’s stay connected"}
          description={"It's never been easier to get in touch with Lanzz. Call us, use our live chat widget or email and we'll get back to you as soon as possible!"}
        />
      </div>
      <div className='flex'>
        <BasicButton title={"Open Positions"} HandleButnClick={()=>navigate("/careers")}/>
        <button onClick={()=>navigate("/about")}>About Us</button>
      </div>
    </div>
    <div id={style.BPP4StayConnectedBottom} className='flex'>
        <div id={style.BPP4StayConnectedBottomLeft}>
            {
              connectedData?.map((e)=>{
                return <div className={style.contactCardsList} key={e?.id}> 
                    <div className={`${style.socialMediaIcon} flex`}>
                      {e?.icon}
                    </div>
                    <h2>{e?.title}</h2>
                    {e?.title==="Social" ? <div id={style.socialSec} className='flex'>{socialContent?.map((i)=><Link className={style.socialLink} target='blank' to={i?.path}>{i?.icon}</Link>)}</div> : <p>{e?.content}</p>
                    }
                </div>
              })
            }
        </div>
        <div id={style.BPP4StayConnectedBottomRight}>
          <div>
            <h4>Email</h4>
            <input type="text" placeholder='jhon@gmail.com' />
          </div>
          <div>
            <h4>How can we help you?</h4>
            <textarea  placeholder='your Message' />
          </div>
          <BasicButton title={"Send"} />
        </div>
    </div>
  </div>
}

export default BPP4StayConnected
