import React from 'react'
import style from "../../CSS/Blogs/BPP2BlogsList.module.css"
import "../../CSS/Root.css"
import snap from "../../Media/snap.png"
import apple from "../../Media/apple.png"
import phoneinsta from "../../Media/phoneInsta.png"
import socialMediaBlog from "../../Media/socialmediaBlog.png"
import appleproduct from "../../Media/appleProduct.png"
import BlogCardBP from '../BlogCard-BP'
import { IoSearchOutline } from "react-icons/io5";
import RecentBlogCardBP from '../RecentBlogCard-BP'
import AntdCollapse from '../AntdCollapse'
import AntdPagination from '../AntdPagination'

const Data = [
    {
        id:1,
        title:"The Snap Pixel: How It Works and How to Install ",
        desc:"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Molestie blandit nibh risus nisl, scelerisque. Fusce cursus massa ut egestas. bibendum non tristique nullam. Netus nisl, leo nunc eu tincidunt vivamus nisi suspendisse. Non morbi eleifend sit ac.",
        featuredImage:snap
    },
    {
        id:2,
        title:"The Snap Pixel: How It Works and How to Install ",
        desc:"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Molestie blandit nibh risus nisl, scelerisque. Fusce cursus massa ut egestas. bibendum non tristique nullam. Netus nisl, leo nunc eu tincidunt vivamus nisi suspendisse. Non morbi eleifend sit ac.",
        featuredImage:apple
    },
    {
        id:3,
        title:"The Snap Pixel: How It Works and How to Install ",
        desc:"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Molestie blandit nibh risus nisl, scelerisque. Fusce cursus massa ut egestas. bibendum non tristique nullam. Netus nisl, leo nunc eu tincidunt vivamus nisi suspendisse. Non morbi eleifend sit ac.",
        featuredImage:phoneinsta
    },
    {
        id:4,
        title:"The Snap Pixel: How It Works and How to Install ",
        desc:"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Molestie blandit nibh risus nisl, scelerisque. Fusce cursus massa ut egestas. bibendum non tristique nullam. Netus nisl, leo nunc eu tincidunt vivamus nisi suspendisse. Non morbi eleifend sit ac.",
        featuredImage:socialMediaBlog
    },
    {
        id:5,
        title:"The Snap Pixel: How It Works and How to Install ",
        desc:"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Molestie blandit nibh risus nisl, scelerisque. Fusce cursus massa ut egestas. bibendum non tristique nullam. Netus nisl, leo nunc eu tincidunt vivamus nisi suspendisse. Non morbi eleifend sit ac.",
        featuredImage:appleproduct
    },
]

const categeoryList = [
    {
        key: 1,
        label: "TECHNOLOGY",
        children: <p></p>,
        className:style.collapsePanel,
        style:{border:'none'}
    },
    {
        key: 2,
        label: "BUSINESS",
        children: <p></p>,
        className:style.collapsePanel,
        style:{border:'none'}
    },
    {
        key: 3,
        label: "BANKING",
        children: <p></p>,
        className:style.collapsePanel,
        style:{border:'none'}
    },
    {
        key: 4,
        label: "FINANCE",
        children: <p></p>,
        className:style.collapsePanel,
        style:{border:'none'}
    },
    {
        key: 5,
        label: "AUTOMOBILE",
        children: <p></p>,
        className:style.collapsePanel,
        style:{border:'none'}
    },
]

const Tags = [
    {
        id:1,
        tag:'Business'
    },
    {
        id:2,
        tag:'Technology'
    },
    {
        id:3,
        tag:'Finance'
    },
    {
        id:4,
        tag:'Banking'
    },
    {
        id:5,
        tag:'Automobile'
    },
    {
        id:6,
        tag:'IT Companies'
    },
    {
        id:7,
        tag:'Networking'
    },
    {
        id:8,
        tag:'Data Handeling'
    },
    {
        id:9,
        tag:'Social Media'
    }
]


const BPP2BlogsList = () => {
  return <div id={style.BPP2BlogsList} className='flex'>
    <div id={style.SearchBoxTop} className='flex'>
        <IoSearchOutline/>
        <input type="text" placeholder='Search for Blogs...' />
    </div>
    <div id={style.BlogsList}>
        {
            Data?.map((e)=>{
                return <BlogCardBP {...e} key={e.id} />
            })
        }

        <div id={style.pagination} className='flex'>
            <AntdPagination 
                total={50}
                pagiStyle={style.blogsListPagination}
            />
        </div>
    </div>
    <div id={style.RecentBlogs}>
        <div id={style.SearchBox} className='flex'>
            <IoSearchOutline/>
            <input type="text" placeholder='Search for Blogs...' />
        </div>
        <div id={style.RecentBlogsList}>
            <h2>RECENT POSTS</h2>
            {
                Data?.map((e)=>{
                    return <RecentBlogCardBP {...e} key={e?.id} />
                })
            }
        </div>
        <div id={style.blogsCategory}>
            <h2>CATEGORY</h2>
            <AntdCollapse items={categeoryList} />
        </div>
        <div id={style.blogsTags}>
            <h2>Tags</h2>
            <div id={style.blogTagsList}>
                {
                    Tags.map((e)=><p key={e?.id}>{e?.tag}</p>)
                }
            </div>
        </div>
    </div>
  </div>
}

export default BPP2BlogsList
