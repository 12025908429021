import React from 'react'
import style from '../../CSS/Services/SPP1Hero.module.css'
import "../../CSS/Root.css"

const SPP1Hero = () => {
  return <div id={style.SPP1Hero} className='flex'>
    <div>
        <h1>Unlocking Breakthroughs: Our Services and Delivery Approach</h1>
        <p>Enabling business success through strategic Digital Solutions and Engineering excellence</p>
    </div>
  </div>
}

export default SPP1Hero
