import React from 'react'
import { Route, Routes } from 'react-router-dom'
// import UnderMaintainance from '../Pages/UnderMaintainance'
import HomePage from '../Pages/HomePage'
import ServicesPage from '../Pages/ServicesPage'
import AboutPage from '../Pages/AboutPage'
import BlogsPage from '../Pages/BlogsPage'
import PrivacyPolicyPage from '../Pages/PrivacyPolicyPage'
import HowWeWorkPage from '../Pages/HowWeWorkPage'
import ContactPage from '../Pages/ContactPage'
import Error404Page from '../Pages/Error404Page'
import Careers from '../Pages/Careers'
import JobOpeningPage from '../Pages/JobOpeningPage'

const Routers = () => {


  return <Routes>
    <Route path='*' element={<Error404Page/>} />
    <Route path='/' element={<HomePage/>} />
    {/* <Route path='/' element={<UnderMaintainance/>} /> */}
    <Route path='/services' element={<ServicesPage/>} />
    <Route path='/about' element={<AboutPage/>} />
    <Route path='/blogs' element={<BlogsPage/>} />
    <Route path='/privacy-policy' element={<PrivacyPolicyPage/>} />
    <Route path='/how-we-work' element={<HowWeWorkPage/>} />
    <Route path='/contact' element={<ContactPage/>} />
    <Route path='/careers' element={<Careers/>} />
    <Route path='/job-openings' element={<JobOpeningPage/>} />
    
  </Routes>
}

export default Routers
