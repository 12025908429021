import React, { useEffect, useState } from 'react'
import style from "../CSS/NavBar.module.css"
import  "../CSS/Root.css"
import lanzzWhiteLogo from "../Media/Lanzz_White_Logo.png"
import lanzzBlueLogo from "../Media/lanzzBlueLogo.png"
import { Link, useLocation, useNavigate } from 'react-router-dom'
import BasicButton from './BasicButton'
import { MdMenu, MdClose } from "react-icons/md";
import MenuBar from './MenuBar'


const NavBar = () => {

    const Navigate = useNavigate()
    const location = useLocation()

    
    const [navColor, setnavColor] = useState(()=>location.pathname==="/"||location.pathname==="/about" || location.pathname==="/careers"?"transparent":"white");
    const [LinkColor, setLinkColor] = useState(()=>location.pathname==="/"||location.pathname==="/about" || location.pathname==="/careers"?"white":"black");
    // const [LogoSize, setlogoSize] = useState("8vw");
    const [shadow,setShadow] = useState("box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;")
    const [logo,setLogo] = useState(()=>location.pathname==="/"||location.pathname==="/about" || location.pathname==="/careers"?lanzzWhiteLogo:lanzzBlueLogo)
    const [navPadding,setNavPadding] = useState("2vh 0px")

    const [menuOpen,setmenuOpen] = useState(false)
   
    
    const listenScrollEvent = () => {
        window.scrollY > 10 ? setnavColor("white") : setnavColor(()=>location.pathname==="/"||location.pathname==="/about" || location.pathname==="/careers"?"transparent":"transparent");
        window.scrollY > 10 ? setNavPadding("0px") : setNavPadding("2vh 0px");
        window.scrollY > 10 ? setLinkColor("black") : setLinkColor(()=>location.pathname==="/"||location.pathname==="/about" ||location.pathname==="/careers"?"white":"black");
        // window.scrollY > 10 ? setlogoSize("8vw") : setlogoSize("8vw");
        window.scrollY > 10 ? setShadow("rgba(100, 100, 111, 0.2) 0px 7px 29px 0px") : setShadow("");
        window.scrollY > 10 ? setLogo(lanzzBlueLogo) : setLogo(()=>location.pathname==="/"||location.pathname==="/about" || location.pathname==="/careers"?lanzzWhiteLogo:lanzzBlueLogo);
    };

    useEffect(() => {
        // console.log(location)
        setmenuOpen(false)

        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        })

        listenScrollEvent()
        
        window.addEventListener("scroll", listenScrollEvent);
        return () => {
            window.removeEventListener("scroll", listenScrollEvent);
        };



        
    },[location.pathname]);

    


    const LINKS = [
        {
            id:1,
            title:"Services",
            path:"/services"
        },
        {
            id:2,
            title:"How We Work",
            path:"/how-we-work"
        },
        {
            id:3,
            title:"Blogs",
            path:"/"
        },
        {
            id:4,
            title:"Careers",
            path:"/careers"
        },
        {
            id:5,
            title:"About",
            path:"/about"
        },
    ]



    return <nav style={{backgroundColor:navColor,padding:navPadding,boxShadow:shadow}}>
        <div className="flex">
            <div id={style.leftPart} className="flex">
                <Link to={"/"}>
                    <img 
                        src={logo} 
                        alt="Lanzz Tech"
                    />
                </Link>
            </div>
            <div id={style.middlePart} className="flex">
                {
                    LINKS?.map((e)=>{
                        return <Link key={e?.id} to={e?.path} className={style.menu} style={{color:LinkColor}}>{e?.title}</Link>
                    })
                }
            </div>
            <div id={style.rightPart} className="flex">
                <BasicButton title={"Contact Us"} HandleButnClick={()=>Navigate("/contact")}/>
                {
                    menuOpen ? 
                    <MdClose className={style.MenuIcon} style={{color:LinkColor}} onClick={()=>setmenuOpen(false)}/>
                    :
                    <MdMenu className={style.MenuIcon} style={{color:LinkColor}} onClick={()=>setmenuOpen(true)}/>
                }
            </div>
            <MenuBar menuOpen={menuOpen}/>
        </div>
    </nav>
}

export default NavBar
