import React from 'react'
import style from "../../CSS/JobOpening/JOP1Top.module.css"
import "../../CSS/Root.css"
import BasicButton from '../BasicButton'
import { FaLinkedinIn, FaLocationDot, FaRegClock, FaTwitter } from "react-icons/fa6";
import { PiHandbagBold } from "react-icons/pi";
import { MdDateRange } from "react-icons/md";
import { IoBookOutline, IoLogoInstagram } from "react-icons/io5";
import { HiOutlineCalendarDays } from "react-icons/hi2";
import { BiChair } from "react-icons/bi";
import { Link } from 'react-router-dom';
import { FaFacebookF } from "react-icons/fa6";


const lookingFor = [
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    "Nullam dictum ligula a gravida porta.",
    "Nam pellentesque orci ut odio blandit, sit amet elementum augue venenatis.",
    "Vivamus semper magna suscipit leo malesuada, eu dictum velit varius.",
    "Nulla non enim eu quam rutrum dictum in non urna.",
    "Integer et felis a purus convallis condimentum nec vel eros.",
    "Vestibulum porta libero nec aliquet blandit.",
    "Duis pretium sapien vitae felis tincidunt lobortis vel et urna",
    "Cras ut erat eu ante suscipit rutrum."
]


const jobSummaryData = [
    {
        id:1,
        icon:<FaLocationDot/>,
        title:"Location",
        desc:"Remote"
    },
    {
        id:2,
        icon:<PiHandbagBold/>,
        title:"Job Type",
        desc:"Full Time"
    },
    {
        id:3,
        icon:<MdDateRange/>,
        title:"Date Posted",
        desc:"posted 1 month ago"
    },
    {
        id:4,
        icon:<IoBookOutline/>,
        title:"Experience",
        desc:"Experience: 1-3 year"
    },
    {
        id:5,
        icon:<FaRegClock/>,
        title:"Working Hours",
        desc:"9 AM - 6 PM"
    },
    {
        id:6,
        icon:<HiOutlineCalendarDays/>,
        title:"Working Days",
        desc:<>Weekly:5days
        <br />
        Weekend: Saturday,Sunday</>
    },
    {
        id:7,
        icon:<BiChair/>,
        title:"Vacancy",
        desc:"No.of Vacancy: 3"
    },
]

const socialMedia = [
    {
        id:1,
        icon:<FaFacebookF/>,
        path:"https://m.facebook.com/profile.php?id=100090838940575",
        bgColor:"#3C5A99"
    },
    {
        id:2,
        icon:<FaTwitter/>,
        path:"https://twitter.com/lanzz_tech",
        bgColor:"#55ACEE"
    },
    {
        id:3,
        icon:<FaLinkedinIn/>,
        path:"https://www.linkedin.com/company/lanzztech",
        bgColor:"#007AB9"
    },
    {
        id:4,
        icon:<IoLogoInstagram/>,
        path:"https://www.instagram.com/lanzztech/",
        bgColor:"#BB346C"
    }
]

const JOP1Top = () => {




  return <div id={style.JOP1Top} className='flex'>


    <div id={style.Left}>
        <h1>Product Engineering</h1>

        <div id={style.LookingFor} className={style.paddingApplied}>
            <h3>Who Are We Looking For</h3>
            <ul>
                {
                    lookingFor?.map((e,i)=>{
                        return <li key={i}>{e}</li>
                    })
                }
            </ul>
        </div>


        <div id={style.Doing}>
            <h3>What You Will Be Doing</h3>
            <ul>
                {
                    lookingFor?.map((e,i)=>{
                        return <li key={i}>{e}</li>
                    })
                }
            </ul>
        </div>


        <div id={style.bonusPoint} className={style.paddingApplied}>
            <h3>Bonus Points for Familiarity with</h3>
            <ul>
                {
                    lookingFor?.map((e,i)=>{
                        return <li key={i}>{e}</li>
                    })
                }
            </ul>
        </div>


        <div id={style.Education}>
            <h3>Educational Requirement</h3>
            <p>Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going</p>
        </div>


        <div id={style.Hours} className={style.paddingApplied}>
            <h3>Working Hours</h3>
            <ul>
                <li>8 AM – 5 PM</li>
            </ul>
        </div>

        <div id={style.Days}>
            <h3>Working Days</h3>
            <ul>
                <li>Weekly: 5 days.</li>
                <li>Weekend: Sunday, Saturday</li>
            </ul>
        </div>

        <div id={style.benefits} className={style.paddingApplied}>
            <h3>Perks & Benefits You’ll Get </h3>
            <ul>
            {
                lookingFor?.map((e,i)=>{
                    return <li key={i}>{e}</li>
                })
            }
            </ul>
        </div>
    </div>



    <div id={style.Right}>
        <div>
            <BasicButton title={"Apply Now"} HandleButnClick={()=>document.querySelector(".ApplyForm").scrollIntoView({behavior:"smooth"})}/>

            <div id={style.keyFeatures}>
                <h4>Job Summary</h4>

                <div>
                    {
                        jobSummaryData?.map((e)=>{
                            return <div key={e?.id} className='flex'>
                                <div className={`${style.jobIcon} flex`} >
                                    {e?.icon}
                                </div>
                                <div className={style.jobDesc} >
                                    <h5>{e?.title}</h5>
                                    <p>{e?.desc}</p>
                                </div>
                            </div>
                        })
                    }
                </div>
            </div>

            <Link id={style.linkStyle} to={"/careers"}>View all Jobs</Link>
        </div>

        <div id={style.share}>
            <p>Share this:</p>
            <div className='flex'>
                {
                    socialMedia.map((e)=>{
                        return <Link to={e?.path} key={e?.id}>
                            <div className='flex' style={{backgroundColor:e?.bgColor}}>
                                {e?.icon}
                            </div>
                        </Link>
                    })
                }
            </div>
        </div>

    </div>


  </div>
}

export default JOP1Top
