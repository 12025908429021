import React, { useState } from 'react'
import style from "../../CSS/About/APP6Contact.module.css";
import "../../CSS/Root.css"
import { HiMiniChatBubbleOvalLeft } from "react-icons/hi2";
import PhoneInput from 'react-phone-input-2';




const APP6Contact = () => {
    const [PhoneNuber,setPhoneNumber] = useState("");

    const handleChange = (value)=>{
        setPhoneNumber(value);
      }


    const HandleSubmit = (e)=>{
        e.preventDefault()
    }


  return <div id={style.APP6Contact} className={`contactAbout flex`}>
    <div className='flex'>
        <div id={style.APP6Left}>
            <div id={style.Icon} className='flex'>
                <HiMiniChatBubbleOvalLeft/>
            </div>
            <p>Get a free consultancy from our expert right now!</p>
        </div>

        <form action="" id={style.APP6ContactForm} onSubmit={HandleSubmit}>

            <div className={style.FormItems}>
                <p>Name <span>*</span></p>
                <input type="text" placeholder='ex. Jhon Doe' />
            </div>
            <div className={style.FormItems}>
                <p>Email <span>*</span></p>
                <input type="text" placeholder='ex. jhondoe@gmail.com' />
            </div>
            <div className={style.FormItems}>
                <p>Phone <span>*</span></p>
                <PhoneInput 
                country={'in'}
                placeholder='ex. 123-456-7890' 
                value={PhoneNuber}
                onChange={handleChange}
                // id={style.phoneInput}
                inputProps={{
                    require: true,
                }}
                inputStyle={{
                    width:"100%",
                    borderRadius:"7px",
                    border:"0px",
                    border: "1px solid rgb(234, 234, 234)"
                }}
                buttonStyle={{
                    border: "1px solid rgb(234, 234, 234)",
                    borderRadius:"7px",
                    borderTopRightRadius:"0px",
                    borderBottomRightRadius:"0px",
                    backgroundColor:"white",  
                    borderLeft:"0",
                }}
                dropdownStyle={{
                    textAlign:"start"
                }}
            />
            </div>
            <div className={style.FormItems} >
                <p>Which service do you need? <span>*</span></p>
                <select name="" id="">
                    <option value="" disabled selected>Select a service</option>
                    <option value="">Web Development</option>
                    <option value="">Product Development</option>
                    <option value="">App Development</option>
                    <option value="">Cloud Storage</option>
                    <option value="">UI/UX Design</option>
                </select>
            </div>
            <input type="submit" id={style.FormSubmit} value={"Get Free Consultancy"}/>
        </form>
    </div>
  </div>
}

export default APP6Contact
