import React from 'react'
import style from "../../CSS/Home/HPP3Technologies.module.css"
import "../../CSS/Root.css"
import Heading from '../Heading'
import technologiesImage from "../../Media/techonologiesVertical4Image.png"





const HPP3Technologies = () => {
  return <div id={style.HPP3Technologies} className='flex' >
        <div className='flex'>
            <div id={style.techLeft}>
              <Heading
                heading={"Technologies & Software Development Experties"} 
                badge={"Technologies"} 
                h1ID={"style.headingALT"}
              />
              <p>
                We're a company that creates personalised software for businesses. We offer a range of services and technology solutions that help your business succeed and achieve great results. Our goal is to help your business reach its full potential and achieve the best possible outcomes using our expertise in software development.
                <br />
                <br />
                Trust Lanzz Tech to handle the complexities of software engineering while you focus on driving your business forward.      
              </p>
            </div>
            <div id={style.techRight} className='flex'>
              <img src={technologiesImage} alt="" />
              <img src={technologiesImage} alt="" />
            </div>
        </div>
  </div>
}

export default HPP3Technologies
