import React from 'react'
import style from "../../CSS/Careers/CAP7.module.css"
import BasicButton from '../BasicButton'


const CAP7 = () => {
  return <div id={style.CAP7}>
    <h2>We’re always on the lookout for talents</h2>
    <p>Can’t find a job you’re looking for? Send us your CV and we’ll get in touch.</p>
    <BasicButton
      title={"Write to us"}
    />
  </div>
}

export default CAP7
