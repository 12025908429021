import React from 'react'
import "../../CSS/Root.css"
import style from "../../CSS/Careers/CAP4.module.css"
import curlyC1 from "../../Media/curlyV1.png"
import curlyC2 from "../../Media/curlyV2.png"
import careersectopn4 from "../../Media/careersSection4.png"

const CAP4 = () => {
  return <div id={style.CAP4}>
    <div id={style.CAP4heading}>
        <h1>Work from anywhere. You decide</h1>
    </div>
    <div id={style.CAP4Bottom}>
        <h1>Lanzz Is More Than A Great Fit For You</h1>
        <div id={style.contentBar}>When you join us, you’re joining our vision to create</div>



        <div className={`${style.circleRing} ${style.ring1} flex`}>
            Find harmony: work for growth, live for fulfillment, cherish both.
        </div>
        <div className={`flex ${style.bulletdot} ${style.Bullet1}`}>
            <div>

            </div>
        </div>
        <img id={style.curlyV1} src={curlyC1} alt="" />
        <div className={`flex ${style.bulletdot} ${style.Bullet2}`}>
            <div>
                
            </div>
        </div>
        <div className={`${style.circleRing} ${style.ring2} flex`}>
            Embrace disruption, innovate boldly, shape the future with transformative tech.
        </div>
        <div className={`flex ${style.bulletdot} ${style.Bullet3}`}>
            <div>
            </div>
        </div>
        <img id={style.curlyV2} src={curlyC2} alt="" />
        <div className={`flex ${style.bulletdot} ${style.Bullet4}`}>
            <div>
                
            </div>
        </div>
        <div className={`${style.circleRing} ${style.ring3} flex`}>
            Vibrant, effective workplace: open, empathetic, respectful, supportive, inclusive.
        </div>
    </div>
    <img id={style.careersImage} src={careersectopn4} alt="" />
  </div>
}

export default CAP4
