import React from 'react'
import style from "../CSS/BlogCardBP.module.css"

const BlogCardBP = ({id,title,desc,featuredImage}) => {
  return <div className={style.BlogCardBP} key={id}>
    <div className={`${style.BlogCardBPTop} flex`}>
        <img src={featuredImage} alt="" />
    </div>
    <div className={style.BlogCardBPBottom}>
        <h2>{title}</h2>
        <p>{desc}</p>
    </div>
    <p>Read More <span>→</span></p>
  </div>
}

export default BlogCardBP
