import React from 'react'
import style from "../../CSS/About/APP1Hero.module.css"
import "../../CSS/Root.css"


const APP1Hero = () => {
  return <div id={style.APP1Hero} className='flex'>
    <div>
        <h6>ABOUT</h6>
        <h1>Exploring Our Inspiring Journey of Innovation and Excellence</h1>
        <p>Discover the heart behind our mission—a narrative of passion, innovation, and unwavering dedication.</p>
    </div>
  </div>
}

export default APP1Hero
