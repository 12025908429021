import React from 'react'
import style from "../CSS/ServiceCard.module.css"

const SearviceCard = ({id,icon,iconStyle,title,description,}) => {
  return <div className={style.SearviceCard} key={id} style={{borderColor:iconStyle.color}}>
    <div>
        <div className={style.icon} style={iconStyle}>
            {icon}
        </div>
        <h2>{title}</h2>
    </div>
    <p>{description}</p>
    {/* <span>Read more →</span> */}
  </div>
}

export default SearviceCard
