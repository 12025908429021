import React from 'react'
import style from "../../CSS/Blogs/BPP3Help.module.css"
import "../../CSS/Root.css"
import BasicButton from '../BasicButton'
import { useNavigate } from 'react-router-dom'

const BPP3Help = () => {

  const navigate = useNavigate()

  return <div id={style.BPP3Help} className='flex'>
    <div className='flex'>
        <h1>What can we help you acheive ?</h1>
        <div id={style.Butns} className='flex'>
            <BasicButton title={"Work with us"} HandleButnClick={()=>navigate("/careers")}/>
            <BasicButton title={"Apply For Job"} HandleButnClick={()=>navigate("/careers")}/>
        </div>
    </div>
  </div>
}

export default BPP3Help
