import React, { useEffect } from 'react'
import JOP1Top from '../Components/JobOpeningPage/JOP1-Top'
import JOP2ApplicationForm from '../Components/JobOpeningPage/JOP2-ApplicationForm'
import { useLocation } from 'react-router-dom';

const JobOpeningPage = () => {

  const location = useLocation();


  useEffect(()=>{ 
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  },[location.pathname])

  return <div>
    <JOP1Top/>
    <JOP2ApplicationForm/>
  </div>
}

export default JobOpeningPage
