import React, { useState } from 'react'
import style from "../../CSS/Home/HPP11Contact.module.css"
import "../../CSS/Root.css"
import Heading from '../Heading'
import { CiMail, CiMobile3, CiUser } from "react-icons/ci";
import { PiBuildingsLight, PiUploadSimpleLight } from "react-icons/pi";
import BasicButton from '../BasicButton';
import CalendelySCH from '../Calendely';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css'



const data=[
    {
        id:1,
        title:"Name",
        placeholder:"Jhon Carter",
        icon:<CiUser color="#556987"/>,
        imp:true,
        validText:"email is not valid!"
    },
    {
        id:2,
        title:"Email",
        placeholder:"jhoncarter@gmail.com",
        icon:<CiMail color="#556987"/>,
        imp:true,
        validText:"enter a vaild email!"
    },
    // {
    //     id:3,
    //     title:"Phone Number",
    //     placeholder:"(123) 456 - 7890",
    //     icon:<CiMobile3 color="#556987"/>,
    //     imp:true,
    //     validText:"Phone Number is not valid!"
    // },
    {
        id:4,
        title:"Company",
        placeholder:"Company Name",
        icon:<PiBuildingsLight color="#556987"/>,
        imp:true,
        // validText:"email is not valid!"
    },
]



const HPP11Contact = () => {
    const [PhoneNuber,setPhoneNumber] = useState("");
    const [valid,setValid] = useState(true)

    const handleChange = (value)=>{
      setPhoneNumber(value);
      setValid(validation(value))
    }

    const validation = (num)=>{
        const PhoneNuberPattern = /^\d{10}$/;
        return PhoneNuberPattern.test(num);
    }


    
  return <div id={style.HPP11Contact} className='homeContact'>
        <div id={style.HPP11ContactTop}>
           <div>
                <Heading    
                    heading={"TELL US ABOUT YOUR PROJECT"}
                    badge={"CONTACT US"}
                />
                <p>
                    Share The Basic Information About Your Project-  Like Expectations, Challenges, and Timeframes.
                    <br />
                    <br />
                    We’ll come back within 24 hours to set up an introcall, sign NDA  and Discuss your project in Detail. 
                </p>
           </div>
        </div>
        
        <div>
            <div id={style.HPP11ContactBottom} className='flex'>
                <div id={style.HPP11ContactBottomLeft}>
                    <div>
                        {
                            data?.map((e)=>{
                                return <div className={style.FormItem} key={e.id}>
                                    <h5>{e?.title} <span>{e?.imp?"*":""}</span></h5>
                                    <div className='flex'>
                                        <input placeholder={e?.placeholder} type="text" />
                                        {e?.icon}
                                    </div>
                                    <p>{e?.validText}</p>
                                </div>
                            })
                        }

                        <div className={` ${style.div4} ${style.FormItem}`}>
                            <h5>Phone Number <span>*</span></h5>
                            <div className='flex'>
                            <PhoneInput 
                                country={'in'}
                                placeholder='Your Mobile Number' 
                                value={PhoneNuber}
                                onChange={handleChange}
                                id={style.phoneInput}
                                inputProps={{
                                    require: true,
                                }}
                                inputStyle={{
                                    width:"100%",
                                    borderRadius:"7px",
                                    border:"0px",
                                    boxShadow: "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px",
                                  }}
                                  buttonStyle={{
                                      border:"0px",
                                      borderRadius:"7px",
                                      borderTopRightRadius:"0px",
                                      borderBottomRightRadius:"0px",
                                      backgroundColor:"white",  
                                      borderRight:"0px"
                                  }}
                            />
                            </div>
                            {/* {!valid && <p>please enter a valid 10-digit phone number!</p>} */}
                        </div>

                        

                    </div>
                    <div>
                        <textarea 
                            name="" 
                            id="" 
                            cols="30" 
                            rows="10"
                            placeholder='How can we help you?'
                        >
                        </textarea>
                        <label className={style.attachFile}>
                            <div className='flex'>
                                <PiUploadSimpleLight/>
                                Upload Additional file
                            </div>
                            <input  type="file" name="" id="" autoComplete='off' />
                        </label>
                        <p>Attach file. File size of your documents should not exceed 10MB</p>
                    </div>
                    <div>
                        <label className='flex'>
                            <input type="checkbox" />
                            I want to protect my data by signing an NDA
                        </label>
                        <BasicButton title={"SEND MESSAGE"}/>
                    </div>
                </div>
                <div id={style.HPP11ContactBottomRight}>
                    {/* <!-- Calendly inline widget begin --> */}
                    <CalendelySCH idOk={style.CalendelySCH}/>
                    
                    {/* <!-- Calendly inline widget end --> */}
                </div>
            </div>
        </div>
  </div>
}

export default HPP11Contact
