import React, { useState } from "react";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import {
//   faChevronRight,
//   faChevronLeft
// } from "@fortawesome/free-solid-svg-icons";
import "../CSS/Root.css"
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { GoDotFill } from "react-icons/go";




const data = [
    {
      id: 1,
    //   icon: <FontAwesomeIcon icon={faCameraRetro} size="3x" />,
      title: "Expertise",
      desc:"With a team of seasoned professionals and specialists in various domains, we bring a wealth of expertise to the table. From product engineering to cloud-native solutions and data engineering, our diverse skill set ensures that we can tackle any challenge with confidence and precision."
    },
    {
      id: 2,
    //   icon: <FontAwesomeIcon icon={faGlassCheers} size="3x" />,
      title: "Innovation",
      desc:"Innovation is at the heart of everything we do. We're constantly exploring new technologies, methodologies, and approaches to deliver cutting-edge solutions that push the boundaries of what's possible. Whether it's pioneering new features or optimizing existing processes, innovation drives our every endeavor."
    },
    {
      id: 3,
    //   icon: <FontAwesomeIcon icon={faDragon} size="3x" />,
      title: "Collaboration",
      desc:"We believe in the power of collaboration. By working closely with our clients, we gain a deep understanding of their goals, challenges, and aspirations. This collaborative approach allows us to tailor our solutions to meet their unique needs and deliver maximum value."
    },
    {
      id: 4,
    //   icon: <FontAwesomeIcon icon={faFootballBall} size="3x" />,
      title: "Quality",
      desc:"Quality is non-negotiable for us. We adhere to stringent quality standards and best practices at every stage of the project lifecycle, ensuring that our deliverables are of the highest caliber. From meticulous testing to thorough documentation, we leave no stone unturned in our pursuit of excellence."
    },
    {
      id: 5,
    //   icon: <FontAwesomeIcon icon={faHelicopter} size="3x" />,
      title: "Customer Satisfaction",
      desc:"Our ultimate goal is customer satisfaction. We go above and beyond to exceed our clients' expectations, delivering solutions that not only meet their requirements but also delight them. Our commitment to exceptional service and support ensures that our clients can trust us to deliver results, time and time again."
    }
  ];
  

const  ServicesSlider = () => {
  const [activeSlide, setactiveSlide] = useState(2);

//   const next = () =>
//     activeSlide < data?.length - 1 && setactiveSlide(activeSlide + 1);

//   const prev = () => activeSlide > 0 && setactiveSlide(activeSlide - 1);

  const getStyles = (index) => {
    if (activeSlide === index)
      return {
        opacity: 1,
        transform: "translateX(0px) translateZ(0px) rotateY(0deg)",
        zIndex: 10
      };
    else if (activeSlide - 1 === index)
      return {
        opacity: 1,
        transform: "translateX(-240px) translateZ(-400px) rotateY(35deg)",
        zIndex: 9
      };
    else if (activeSlide + 1 === index)
      return {
        opacity: 1,
        transform: "translateX(240px) translateZ(-400px) rotateY(-35deg)",
        zIndex: 9
      };
    else if (activeSlide - 2 === index)
      return {
        opacity: 1,
        transform: "translateX(-480px) translateZ(-500px) rotateY(35deg)",
        zIndex: 8
      };
    else if (activeSlide + 2 === index)
      return {
        opacity: 1,
        transform: "translateX(480px) translateZ(-500px) rotateY(-35deg)",
        zIndex: 8
      };
    else if (index < activeSlide - 2)
      return {
        opacity: 0,
        transform: "translateX(-480px) translateZ(-500px) rotateY(35deg)",
        zIndex: 7
      };
    else if (index > activeSlide + 2)
      return {
        opacity: 0,
        transform: "translateX(480px) translateZ(-500px) rotateY(-35deg)",
        zIndex: 7
      };
  };

  return (
    <>
      {/* carousel */}
      <div className="slideC">
        {data?.map((item, i) => (
          <React.Fragment key={item.id}>
            <div
              className="slide"
              style={{
                boxShadow: `0 5px 20px ${item.bgColor}30`,
                ...getStyles(i)
              }}
            >
              <SliderContent {...item} />
            </div>
          </React.Fragment>
        ))}
      </div>
      {/* carousel */}

      <div className="btns">
        {
            data?.map((e,i)=>{
                return <GoDotFill
                className="btn"
                onClick={()=>setactiveSlide(i)}
                // icon={faChevronLeft}
                color="#fff"
                fontSize="20px"
                style={{
                    border:activeSlide===i?"3px solid #0474BC":"0px"
                }}
            />
            })
        }
      </div>
    </>
  );
};

const SliderContent = (props) => {
  return (
    <div className="sliderContent" >
      {props.icon}
      <h2>{props.title}</h2>
      <p>{props.desc}</p>
    </div>
  );
};


export default ServicesSlider