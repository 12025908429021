import React from 'react'
import style from "../CSS/Footer.module.css"
import "../CSS/Root.css"
import lanzzWhiteLogo  from "../Media/Lanzz_White_Logo.png"
import { IoLocationOutline, IoMailOutline } from "react-icons/io5";
import { FaInstagram, FaFacebookF, FaXTwitter, FaLinkedinIn, FaGithub } from "react-icons/fa6";
import { NavLink } from 'react-router-dom';



const socialMediaLinks = [
    {
        id:1,
        icon:<FaInstagram/>,
        link:"https://www.instagram.com/lanzztech/"
    },
    {
        id:2,
        icon:<FaFacebookF/>,
        link:"https://m.facebook.com/profile.php?id=100090838940575"
    },
    {
        id:3,
        icon:<FaXTwitter/>,
        link:"https://twitter.com/lanzz_tech"
    },
    {
        id:4,
        icon:<FaLinkedinIn/>,
        link:"https://www.linkedin.com/company/lanzztech"
    }
]

const ServicesLink = [
    {
        id:2,
        title:"Custom Web Development",
        link:""
    },
    {
        id:3,
        title:"Mobile Application Development",
        link:""
    },
    {
        id:4,
        title:"Custom Software Development for Startups",
        link:""
    },
    {
        id:5,
        title:"QA and Testing",
        link:""
    },
    {
        id:6,
        title:"DevOps as a Service",
        link:""
    },
    {
        id:8,
        title:"MVP Development",
        link:""
    },
    {
        id:10,
        title:"UI/UX Design",
        link:""
    },
    {
        id:12,
        title:"Software Architecture Consulting",
        link:""
    },
    {
        id:13,
        title:"Product Engineering",
        link:""
    },
]

const ExpertiseLink = [
    {
        id:1,
        title:"Fintech",
        link:""
    },
    {
        id:2,
        title:"eCommerce",
        link:""
    },
    {
        id:3,
        title:"Healthcare",
        link:""
    },
    {
        id:4,
        title:"IoT",
        link:""
    },
    {
        id:5,
        title:"Digital Wallet",
        link:""
    },
    {
        id:6,
        title:"Logistics",
        link:""
    },
    {
        id:7,
        title:"AI Development",
        link:""
    },
    {
        id:8,
        title:"Wealth Development",
        link:""
    }
]

const TechnologiesLink = [
    {
        id:1,
        title:"React",
        link:""
    },
    {
        id:2,
        title:"Javascript",
        link:""
    },
    {
        id:3,
        title:"JAVA",
        link:""
    },
    {
        id:4,
        title:"Python",
        link:""
    },
    {
        id:5,
        title:"React Native",
        link:""
    },
    {
        id:6,
        title:"Angular",
        link:""
    },
]

const CompanyLink = [
    {
        id:1,
        title:"About",
        link:"/about"
    },
    {
        id:2,
        title:"Privacy Policy",
        link:"/privacy-policy"
    },
    {
        id:3,
        title:"Contact Us",
        link:"/contact"
    },
    {
        id:4,
        title:"Blogs",
        link:""
    },
    {
        id:5,
        title:"Services",
        link:"services"
    },
    {
        id:6,
        title:"How We Work",
        link:"how-we-work"
    }
]

const CareersLink = [
    {
        id:1,
        title:"Work at LanzzTech",
        link:"/how-we-work"
    },
    {
        id:2,
        title:"Open positions",
        link:"/careers"
    },
]
const CloudDevops = [
    {
        id:1,
        title:"DevOps Consulting",
        link:""
    },
    {
        id:2,
        title:"Cloud Native Solutions",
        link:""
    },
]
const DataAI = [
    {
        id:1,
        title:"Data Engineering",
        link:""
    },
    {
        id:2,
        title:"Data Analytics",
        link:""
    },
    {
        id:3,
        title:"Big Data",
        link:""
    },
    {
        id:4,
        title:"Data Warehousing Solutions",
        link:""
    },
]



const Footer = () => {


  return <div id={style.Footer} className='flex'>
        <div >
            <div id={style.CompanyProfile}>
                <img src={lanzzWhiteLogo} alt="Lanzz Tech" />
                <div>
                    <h4>Contact Us</h4>
                    <label className='flex'>
                        <IoMailOutline/>
                        <p>contact@lanzztech.com</p>
                    </label>
                    <h4>Address</h4>
                    <label className='flex'>
                        <IoLocationOutline/>
                        <p>Pune, Maharashtra</p>
                    </label>
                    <h4>Privacy Policy</h4>
                    <label className='flex'>
                        <p>© Copyright 2024 Lanzz Tech.
                            <br />
                            All rights reserved.
                        </p>
                    </label>
                </div>
                <div className='flex'>
                    {
                        socialMediaLinks?.map((e)=>{
                            return <NavLink className={`${style.NavLinkIcon} flex`} target='blank' to={e?.link}  key={e?.id}> {e?.icon} </NavLink>
                        })
                    }
                </div>
            </div>
            <div id={style.Services}>
                <h4>Services</h4>
                <div>
                    {
                        ServicesLink?.map((e)=>{
                            return <span className={style.NavLink} key={e?.id}> {e?.title}</span>
                        })
                    }
                </div>
            </div>
            <div id={style.Expertise}>
                <h4>Expertise</h4>
                <div>
                    {
                        ExpertiseLink?.map((e)=>{
                            return <span className={style.NavLink} key={e?.id}> {e?.title}</span>
                        })
                    }
                </div>
            </div>
            <div id={style.Technologies}>
                <h4>Technologies</h4>
                <div>
                    {
                        TechnologiesLink?.map((e)=>{
                            return <span className={style.NavLink} key={e?.id}> {e?.title}</span>
                        })
                    }
                </div>
            </div>
            <div id={style.Careers}>
                <h4>Careers</h4>
                <div>
                    {
                        CareersLink?.map((e)=>{
                            return <NavLink key={e?.id} to={e?.link} className={style.NavLink}>{e?.title}</NavLink>
                        })
                    }
                </div>
            </div>
            <div id={style.Company}>
                <h4>Company</h4>
                <div>
                    {
                        CompanyLink?.map((e)=>{
                            return <NavLink key={e?.id} to={e?.link} className={style.NavLink}>{e?.title}</NavLink>
                        })
                    }
                </div>
            </div>
            <div id={style.cloudDevops}>
                <h4>Cloud & DevOps</h4>
                <div>
                    {
                        CloudDevops?.map((e)=>{
                            return <NavLink key={e?.id} to={e?.link} className={style.NavLink}>{e?.title}</NavLink>
                        })
                    }
                </div>
            </div>
            <div id={style.dataAI}>
                <h4>Data & AI</h4>
                <div>
                    {
                        DataAI?.map((e)=>{
                            return <NavLink key={e?.id} to={e?.link} className={style.NavLink}>{e?.title}</NavLink>
                        })
                    }
                </div>
            </div>
        </div>
  </div>
}

export default Footer
