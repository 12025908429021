import React from 'react';
import { ClockCircleOutlined } from '@ant-design/icons';
import { ConfigProvider, Timeline } from 'antd';



const TimelineCareersProcess = ({items}) => (
  <ConfigProvider
    theme={{
        components: {
            Timeline: {
                tailWidth:"3px",
                tailColor:"rgba(230, 235, 242, 1)",
                dotBg:"transparent"
            },
        },
    }}
    >
   <Timeline
    mode="alternate"
    items={items}
  />
</ConfigProvider>
);
export default TimelineCareersProcess;


