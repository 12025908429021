import React from 'react'
import style from "../../CSS/About/APP3Values.module.css"
import "../../CSS/Root.css"
import IntelligentIMG from "../../Media/Inntelligent.png"
import GlobalIMG from "../../Media/Global.png"
import AgileIMG from "../../Media/Agile.png"
import ClientCentricIMG from "../../Media/ClientCentric.png"
import NonHierarchicalIMG from "../../Media/NonHierarchical.png"
import ResponsibleIMG from "../../Media/Responsible.png"


const Data = [
    {
        id:1,
        title:"Intelligent",
        desc:"We prioritize intelligence, results, boldness, over location and restrictive structures.",
        icon:IntelligentIMG
    },
    {
        id:2,
        title:"Global",
        desc:"Global presence, diverse, fostering collective intelligence, learning for tomorrow's success.",
        icon:GlobalIMG
    },
    {
        id:3,
        title:"Agile",
        desc:"We excel in doing, correcting, exploring, innovating, breaking barriers, and iterating.",
        icon:AgileIMG
    },
    {
        id:4,
        title:"Client-Centric",
        desc:"We care about our clients, about their success, and about the partnership we aim to build.",
        icon:ClientCentricIMG
    },
    {
        id:5,
        title:"Non-Hierarchical",
        desc:"We shape the future, empowering colleagues to be brave, creative, and responsible leaders.",
        icon:NonHierarchicalIMG
    },
    {
        id:6,
        title:"Responsible",
        desc:"We take ownership, acknowledging successes, errors, valuing colleagues, and sustainability.",
        icon:ResponsibleIMG
    },
]


const APP3Values = () => {
  return <div id={style.APP3Values} className='flex'>
    <div>
        <div id={style.headingBadge}>VAlUES</div>
        <h1 id={style.heading}>Our Values</h1>
        <p id={style.headingDescription}>We are powered by caring. Caring is at the heart of our values. It drives us to deliver the best results, to be the best colleagues, and deliver the best solutions.</p>
        
        <div id={style.valuesCardList}>
            {
                Data?.map((e)=>{
                    return <div className={style.valueCard} key={e?.id}>
                        <img src={e?.icon} alt={e?.title} />
                        <h4>{e?.title}</h4>
                        <p>{e?.desc}</p>
                    </div>
                })
            }
        </div>
    </div>
  </div>
}

export default APP3Values
