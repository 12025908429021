import React from 'react'
import style from "../../CSS/Careers/CAP5Openings.module.css"
import { FaArrowRight } from "react-icons/fa6";


const openingsData = [
    {
        id:1,
        title:"Software Engineer",
        desc:"Full Time, Remote"
    },
    {
        id:2,
        title:"Business Development Executive",
        desc:"Full Time, Remote"
    },
]



const CAP5Openings = () => {
  return <div id={style.CAP5Openings} className='currentOpenings'>
    <h2>Current  Job  Openings</h2>
    <div id={style.openingsList} className='flex'>
        {
            openingsData.map((e)=>{
                return <div key={e?.id} className={`${style.openingCard} flex`}>
                    <div>
                        <h4>{e?.title}</h4>
                        <p>{e?.desc}</p>
                    </div>
                    <button className='flex'>
                        <FaArrowRight/>
                    </button>
                </div>
            })
        }
    </div>
  </div>
}

export default CAP5Openings
