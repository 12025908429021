import React, { useState } from 'react'
import style from "../../CSS/Contact/CPP3Contact.module.css"
import "../../CSS/Root.css"
import { CiMail, CiMobile3, CiPhone, CiUser } from "react-icons/ci";
import { PiBuildingsLight, PiUploadSimpleLight,PiNotePencil } from "react-icons/pi";
import PhoneInput from 'react-phone-input-2';


const CPP3Appointment = () => {

    const [PhoneNuber,setPhoneNumber] = useState("");

    const handleChange = (value)=>{
        setPhoneNumber(value);
      }

    const HandleSubmit = (e)=>{
        e.preventDefault();
    }



  return <div id={style.CPP3Appointment} className='flex'>
    <div >
        <div id={style.heading}>
            <p>SCHEDULE YOUR PRESENCE</p>
            <h1>Make an Appointment</h1>
            <p>Schedule your consultation now! Let's discuss your project and how we can assist you in achieving your goals.</p>
        </div>
        <div id={style.formBox}>
            <form action="" onSubmit={HandleSubmit}>
                <div  >
                    <CiUser/>
                    <input type="text" placeholder='Name' />
                </div>
                <div  >
                    <CiMail/>
                    <input type="text" placeholder='Email'/>
                </div>
                <div  >
                    <CiPhone/>
                    <PhoneInput 
                        country={'in'}
                        placeholder='Your Mobile Number' 
                        value={PhoneNuber}
                        onChange={handleChange}
                        // id={style.phoneInput}
                        inputProps={{
                            require: true,
                        }}
                        inputStyle={{
                            width:"100%",
                            borderRadius:"7px",
                            border:"0px",
                        }}
                        buttonStyle={{
                            border:"0px",
                            borderRadius:"7px",
                            borderTopRightRadius:"0px",
                            borderBottomRightRadius:"0px",
                            backgroundColor:"white",  
                            borderLeft:"0",
                        }}
                        dropdownStyle={{
                            textAlign:"start"
                        }}
                    />
                </div>
                <div  >
                    <PiBuildingsLight/>
                    <input type="text" placeholder='Company'/>
                </div>
                <div  >
                    <PiNotePencil />
                    <textarea placeholder='About' />
                </div>
                <input type='submit' value={"Make an Appointment"}/>
            </form>
        </div>
    </div>
  </div>
}

export default CPP3Appointment
