import React from 'react'
import style from "../../CSS/Contact/CPP4Booking.module.css"
import "../../CSS/Root.css"
import { PiWarningCircleFill } from "react-icons/pi";
import CalendelySCH from '../Calendely';
import photo from "../../Media/Favicon.png"
import { GoClock } from "react-icons/go";




const CPP4Booking = () => {
  return <div id={style.CPP4Booking} className='flex calendelyBooking'>
    <div id={style.CPP4BookingLeft}>
      <div id={style.LeftTop} className='flex'>
        <div>
          <img src={photo} alt="" />
          <h4>Lanzz Tech</h4>
        </div>
        <div>
          <h3>Onboarding Call</h3>
          <span className='flex'> <GoClock/> Duration</span>
          <p>30-40 mins</p>
        </div>
      </div>
      <div id={style.LeftBottom}>
        <p>Connect with us to learn how our platform can fuel your business growth</p>
        <ul>
          <li>Get Branding support</li>
          <li>Low Cost</li>
          <li>Efficient Managing</li>
          <li>Pickup/Delivery Option</li>
          <li>Accounting</li>
        </ul>
        <p>Feel free to bring any questions you might have!</p>
      </div>
    </div>
    <div id={style.CPP4BookingRight}>
          <CalendelySCH idOk={style.CalendelySCH}/>
        <div id={style.Tag} className='flex'>
            <div >
                <h3>Hello, Let’s Talk !</h3>
                <p>Schedule a 30 min one-to-one call to dicuss your goals and challenges</p>
                <span className='flex'> <PiWarningCircleFill id={style.Icon} /> This call is optional but highly recommended!</span>
            </div>
        </div>
    </div>
  </div>
}

export default CPP4Booking
