// import React, { useContext, useEffect, useState } from 'react'
import style from "../../CSS/Home/HPP1Hero.module.css"
import "../../CSS/Root.css"
import BasicButton from '../BasicButton'
import lanzzWhiteLogo from "../../Media/Lanzz_White_Logo.png"
import { PiBellSimpleLight, PiQrCodeLight } from "react-icons/pi";
import { IoIosArrowDown } from "react-icons/io";
import curlyArrow from "../../Media/curlyArrow.png"
import HeroVector from "../../Media/vectorHero.png"




const HPP1Hero = () => {

  // const {ProviderData,HandleSetProviderData} = useContext(ContextProvider)


  // const [hasScrolled, setHasScrolled] = useState(false);

  // const [refID,setRefID] = useState()

  // useEffect(() => {
  //   const handleScroll = () => {
  //     if (!hasScrolled) {
  //       // Your code to run only on the first scroll
  //       console.log('This is the first scroll!');
        
  //       // Update state to indicate that scrolling has occurred
  //       setHasScrolled(true);
        
  //       // Optionally, remove event listener after first scroll
  //       window.removeEventListener('scroll', handleScroll);
  //     }
  //   };

  //   // Add event listener for scroll
  //   window.addEventListener('scroll', handleScroll);

  //   // Clean up function to remove event listener on unmount
  //   return () => {
  //     window.removeEventListener('scroll', handleScroll);
  //   };
  // }, [hasScrolled]); // Only re-run effect if hasScrolled state changes


  const HandleScroll = ()=>{
    document.querySelector(".homeServicesSec").scrollIntoView({behavior:"smooth"})
  }

  const HandleButnClick = ()=>{
    document.querySelector(".homeContact").scrollIntoView({behavior:"smooth"})
  }




  return <div id={style.HPP1_Hero}>
    <div>
      <div>
        <h1>A Product Engineering Company.</h1>
        <p>Lanzz Tech provides comprehensive domain knowledge and technical expertise to both startups and established enterprises. As a prominent software development company, we offer a holistic ecosystem that encompasses scalable and high-impact IT consulting services.</p>
      </div>

      <button className='basicButton' id={style.LetsTalkButn}>Let's Talk</button>

      {/* mobile part */}
      <div className='flex'>
        <div className={`${style.divP1} flex`}>
          <div className={`${style.divP2} flex`}>
            <div className={`${style.divP3} flex`}>
              <div className={`${style.divP4} flex`}>
                <div className={`${style.divP5} flex`}>
                  <div className={`${style.divP6} flex`}>
                    <div className={`${style.divP7} flex`}>
                      <div className={`${style.divP8} flex`}>

                      </div>        
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id={style.PhonePart} className='flex'> 
        <div> 
            <div id={style.phoneNav} className='flex'>
              <img src={lanzzWhiteLogo} alt="Lanzz Tech" />
              <div className='flex'>
                  <PiQrCodeLight/>
                  <PiBellSimpleLight/>
              </div>
            </div>
            <div id={style.phoneMiddle}>
              <p>It almost always begins with a conversation.</p>
              <img src={curlyArrow} alt="" />
              <BasicButton title={"let's Talk"} HandleButnClick={HandleButnClick} />
              <IoIosArrowDown className={style.DownArrow} onClick={()=>HandleScroll()}/>
            </div>
        </div>
      </div>

      <img id={style.HeroVectorPhoneVeiw} src={curlyArrow} alt="" />
    </div>
  </div>
}

export default HPP1Hero

