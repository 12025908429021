import React from 'react'
import style from "../CSS/ServiceCardServicePage.module.css"

const ServiceCardServicePage = ({id,title,description}) => {
  return <div id={style.ServiceCardServicePage} className='flex'>
        {
            id%2===1? <div className={`${style.LeftPart} flex`} >
                <h2>{title}</h2>
            </div>
            :
            ""
        }
        <div className={`${id%2===1?  style.MiddlePartRight : style.MiddlePartLeft} flex`}>
            <p>{description}</p>
        </div>
        {
            id%2===0? <div className={`${style.RightPart} flex`}>
                <h2>{title}</h2>
            </div>
            :
            ""
        }
  </div>
}

export default ServiceCardServicePage
