import React from 'react'
import style from "../../CSS/Services/SPP3Expertise.module.css"
import "../../CSS/Root.css"
import Heading from '../Heading'
import one from "../../Media/one.png"
import two from "../../Media/two.png"
import three from "../../Media/three.png"
import four from "../../Media/four.png"
import five from "../../Media/five.png"

const data=[
    {
        id:1,
        title:"Product Engineering",
        description:"We specialize in transforming innovative ideas into scalable, market-ready products. Our holistic approach covers every stage of product development, from conceptualization to delivery, ensuring exceptional quality and performance.",
        number:one
    },
    {
        id:2,
        title:"Cloud Native Solutions",
        description:"Harnessing the power of cloud technology, we design and implement robust solutions that are scalable, resilient, and cost-effective. Our expertise in cloud-native architecture empowers businesses to innovate faster and adapt to changing market demands seamlessly.",
        number:two
    },
    {
        id:3,
        title:"Data Engineering & Data Warehousing Solutions",
        description:"Unlocking the potential of data, we offer comprehensive data engineering and warehousing solutions. From data integration and processing to analytics and visualization, we help businesses derive actionable insights and make data-driven decisions with confidence.",
        number:three
    },
    {
        id:4,
        title:"Front & Back End Services",
        description:"Our end-to-end frontend and backend services ensure seamless user experiences and robust system functionalities. Whether it's crafting intuitive user interfaces or developing scalable backend systems, we deliver solutions that drive engagement and efficiency.",
        number:four
    },
    {
        id:5,
        title:"UI/UX Consulting",
        description:"Elevating digital experiences through thoughtful design and user-centric strategies, our UI/UX consulting services focus on enhancing usability, accessibility, and engagement. We collaborate closely with clients to create visually stunning and intuitive interfaces that captivate users and drive business results.",
        number:five
    }
]

const SPP3Expertise = () => {
  return <div id={style.SPP3Expertise} className='expertiseSercices flex'>
    <div>
        <Heading
            heading={"Our Core Expertise"}
            badge={"Expertise"}
            diff={"Expertise"}
        />
        <div id={style.ExpertiseList}>
            {
                data?.map((e)=>{
                    return <div key={e.id} className={`${e?.id%2===1?style.ExpertiseCardLeft:style.ExpertiseCardRight} flex`}>
                        <div className='flex'>
                            <img src={e.number} alt="" />
                            <div>
                                <h2>{e.title}</h2>
                                <p>{e.description}</p>
                            </div>
                        </div>
                    </div>
                })
            }
        </div>
    </div>
  </div>
}

export default SPP3Expertise
