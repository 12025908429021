import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/splide/dist/css/splide.min.css";
import '@splidejs/react-splide/css/skyblue';

import review from "../Media/Review.png"
import "../CSS/Root.css"

const VerticalCarousel = ({childrenStyle,data})=>{
  return (
    <Splide 
      options={{
        type: "loop",
        gap: "10px",
        drag: false,
        arrows: false,
        pagination: true,
        perPage: 1,
        direction: 'ttb',
        height: '100%',
        width:"100%",
        autoplay:true,
        speed:500,
        // autoScroll: {
        //   pauseOnHover: false,
        //   pauseOnFocus: false,
        //   rewind: false,
        //   speed: 0.5
        // },
        
      }}

      style={{
        width:"98%",
        height:"100%"
      }}
      
    //   extensions={{ AutoScroll }}
    >
      {
        data?.map((e)=>{
            return <SplideSlide style={{height:"100%",width:"100%",justifyContent:"start",marginLeft:"20px"}} className="flex" key={e?.id}>
            <div className={childrenStyle}>
              <p>{e?.review}</p>
              <h4>{e?.position}</h4>
              <span>{e?.company}</span>
            </div>
          </SplideSlide>
          
        })
      }
    </Splide>
  );
}

export default VerticalCarousel;