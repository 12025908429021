import React from 'react'
import style from "../../CSS/Home/HPP4Expertise.module.css"
import "../../CSS/Root.css"
import Heading from '../Heading'
import { GiGearHammer } from "react-icons/gi";
import { FaLaptopCode } from "react-icons/fa6";
import { TbCloudComputing } from "react-icons/tb";
import BasicButton from '../BasicButton';
import dotsPattern from "../../Media/dots.svg"
import circleWavePattern from "../../Media/circleWaves.svg"
import { useNavigate } from 'react-router-dom';



const data=[
    {
        id:1,
        title:"Product Engineering",
        desc:"Crafting innovative solutions tailored to your needs, driving business growth with cutting-edge technology and creativity.",
        icon:<GiGearHammer/>,
        iconStyle:{
            color:"hotpink",
            backgroundColor:"rgba(255, 192, 203, 0.4)"
        }
    },
    {
        id:2,
        title:"Cloud Native Solutions",
        desc:"Leveraging cloud technology to design scalable, resilient, and agile applications for seamless digital transformation.",
        icon:<TbCloudComputing/>,
        iconStyle:{
            color:"green",
            backgroundColor:"rgba(172, 255, 47, 0.4)"
        }
    },
    {
        id:3,
        title:"Front & Back End Services",
        desc:"Building robust, user-centric applications from front-end interfaces to back-end systems, ensuring seamless functionality and performance.",
        icon:<FaLaptopCode/>,
        iconStyle:{
            color:"teal",
            backgroundColor:"rgba(0,128,128,0.2)"
        }
    },
]

const HPP4Expertise = () => {


    const navigate = useNavigate()

  return (
    <div id={style.HPP4Expertise} className='flex'>
      <div>
        <Heading
            heading={"Our Core Expertise"} 
            badge={"Expertise"} 
            description={"Expertise in crafting tailored solutions, leveraging cloud technology, architecting robust data ecosystems, full-stack development, and enhancing user experiences."}
        />

        <div id={style.expBottom} className='flex'>
            <div id={style.expoBottomLeft}>
                <div id={style.expertiseCardList}>
                    {
                        data?.map((e)=>{
                            return <div className={`${style.expertiseCard} flex`} key={e.id}>
                                <div className='flex' style={e.iconStyle}>
                                    {e.icon}
                                </div>
                                <div>
                                    <h3>{e.title}</h3>
                                    <p>{e.desc}</p>
                                </div>
                            </div>
                        })
                    }
                </div>
                <div id={style.ExploreButton} className='flex'>
                    <BasicButton title={"Explore More"} HandleButnClick={()=>{navigate("/services")}}/>
                </div>
            </div>
            <div id={style.expoBottomRight} className='flex'>
                    <div><img src={dotsPattern} alt="" /></div>
                    <div><img src={circleWavePattern} alt="" /></div>
            </div>
        </div>
      </div>
    </div>
  )
}

export default HPP4Expertise
