import React from 'react'
import "../CSS/Root.css"


const BasicButton = ({title,height,fontSize,HandleButnClick,style}) => {
  return <button 
  style={style}
  onClick={()=>HandleButnClick?HandleButnClick():""} 
  className='basicButton' >
    {title}
  </button>
}

export default BasicButton




// style={{height:title==="Contact Now"?height:"auto",fontSize:title==="Contact Now"?fontSize:"2vh",width:title==="SEND MESSAGE"?"95%":"",marginTop:title==="let's Talk"?"4vh":"0px"}}