import React from 'react'
import "../../CSS/Root.css"
import style from "../../CSS/Careers/CAP1Hero.module.css"
import BasicButton from '../BasicButton'

const CAP1Hero = () => {

  

  return <div id={style.CAP1Hero}>
    <div className='flex'>
      <div id={style.content}>
          <h1>Join The Team</h1>
          <p>Lanzz Tech is an employee-centred company that looks after every employee, gives autonomy to make choices, supports self-development and career growth. Our development team is always in search of talented individuals to join our employee-centred culture.</p>
          <span>NAVIGATE BELOW TO SEE OUR OPEN CURRENT POSITIONS</span>
          <br />
          <BasicButton
            title={"Open positions"}
            HandleButnClick={()=>document.querySelector(".currentOpenings").scrollIntoView({behavior: 'smooth'})}
          />
      </div>
    </div>
  </div>
}

export default CAP1Hero
