import React from 'react'
import style from "../../CSS/Services/SPP2Services.module.css"
import "../../CSS/Root.css"
import Heading from '../Heading'
import ServiceCardServicePage from '../ServiceCardServicePage'


const ServiceData = [
    {
      id:1,
      title:"Front End Development",
      description:"Crafting engaging and intuitive user experiences through expert frontend development, tailored to elevate your digital presence and drive user satisfaction."
    },
    {
      id:2,
      title:"Data Engineering",
      description:"Unlocking the power of your data with our tailored solutions. We organize, clean, and transform your data so you can make informed decisions and discover new insights effortlessly."
    },
    {
      id:3,
      title:"Data Analytics",
      description:"Discovering valuable insights hidden within your data. We analyze and interpret your data to provide actionable recommendations, empowering you to make informed decisions and drive business growth with confidence."
    },
    {
      id:4,
      title:"UI/UX Consulting",
      description:"Crafting delightful digital experiences tailored just for you. We offer expert guidance and personalized solutions to enhance user satisfaction, engagement, and overall success of your digital products, ensuring they leave a lasting impression."
    },
    {
      id:5,
      title:"DevOps Consulting",
      description:"Empowering your development journey with streamlined processes and enhanced collaboration. Our expert guidance and support help you optimize software delivery, improve efficiency, and foster a culture of innovation, driving your business forward with confidence and agility.",
    },
    {
      id:6,
      title:"Big Data",
      description:"Unleashing the potential of your data universe. We help you harness vast amounts of information to gain valuable insights, make smarter decisions, and unlock new opportunities for growth and innovation. With our expertise, navigating the complexities of big data becomes an exciting journey towards success."
    },
    {
      id:7,
      title:"AI/ML Development",
      description:"Bringing intelligence to your applications with the magic of AI and machine learning. Our expert team crafts personalized solutions that learn and adapt, making your systems smarter, more efficient, and ready to tackle the challenges of tomorrow with confidence and ease."
    }
  ]


const SPP2Services = () => {
  return <div id={style.SPP2Services}>
    <Heading
        heading={"Our Offerings"}
        badge={"Services"}
    />
    <div id={style.ServicesList}>
        {
            ServiceData.map((e)=>{
                return <ServiceCardServicePage key={e.id} {...e} />
            })
        }
    </div>
  </div>
}

export default SPP2Services
