import React, { useState } from 'react'
import style from "../../CSS/Services/SPP6Contact.module.css"
import { CiMail, CiMobile3, CiUser } from "react-icons/ci";
import { PiBuildingsLight } from "react-icons/pi";
import PhoneInput from 'react-phone-input-2';


const SPP6Contact = () => {
    
    const [PhoneNuber,setPhoneNumber] = useState("");

    const handleChange = (value)=>{
        setPhoneNumber(value);
      }


    const HandleSubmit = (e)=>{
        e.preventDefault();
    }


  return <div id={style.SPP6Contact}>


    <h1>Contact Us</h1>
    <form action="" id={style.contactForm} onSubmit={HandleSubmit}>
        <div>
            <CiUser/> <input type="text" placeholder='Full Name' />
        </div>
        <div>
            <CiMail/> <input type="text" placeholder='Email address' />
        </div>
        <div>
            <CiMobile3/> 
            <PhoneInput 
                country={'in'}
                placeholder='Your Mobile Number' 
                value={PhoneNuber}
                onChange={handleChange}
                // id={style.phoneInput}
                inputProps={{
                    require: true,
                }}
                inputStyle={{
                    width:"100%",
                    borderRadius:"7px",
                    border:"0px",
                }}
                buttonStyle={{
                    border:"0px",
                    borderRadius:"7px",
                    borderTopRightRadius:"0px",
                    borderBottomRightRadius:"0px",
                    backgroundColor:"white",  
                    borderLeft:"0",
                }}
                dropdownStyle={{
                    textAlign:"start"
                }}
            />
        </div>
        <div>
            <PiBuildingsLight/> <input type="text" placeholder='Company' />
        </div>
        <div>
            <textarea placeholder='How can we Help you?' name="" id="" cols="30" rows="10"></textarea>
        </div>
        <input id={style.SubmitInput} type="submit" value={"SEND"}/>
    </form>

    <div id={style.L1}>

    </div>
    <div id={style.S1}>
        
    </div>
    <div id={style.L2}>

    </div>
    <div id={style.S2}>

    </div>

  </div>
}

export default SPP6Contact
