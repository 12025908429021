import React, { useEffect } from 'react'
import CAP1Hero from '../Components/CareersPage/CAP1-Hero'
import CAP2Heading from '../Components/CareersPage/CAP2-Heading'
import CAP3 from '../Components/CareersPage/CAP3'
import CAP4 from '../Components/CareersPage/CAP4'
import CAP5Openings from '../Components/CareersPage/CAP5-Openings'
import CAP6Process from '../Components/CareersPage/CAP6-Process'
import CAP7 from '../Components/CareersPage/CAP7'
import CAP8Contact from '../Components/CareersPage/CAP8-Contact'
import { useLocation } from 'react-router-dom'

const Careers = () => {


  const location = useLocation();


  useEffect(()=>{ 
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  },[location.pathname])

  return <div>
    <CAP1Hero/>
    <CAP2Heading/>
    <CAP3/>
    <CAP4/>
    <CAP5Openings/>
    <CAP6Process/>
    <CAP7/>
    <CAP8Contact/>
  </div>
}

export default Careers
