import React, { useEffect } from 'react'
import HPP1Hero from '../Components/HomePage/HPP1-Hero'
import HPP2Services from '../Components/HomePage/HPP2-Services'
import HPP3Technologies from '../Components/HomePage/HPP3-Technologies'
import HPP4Expertise from '../Components/HomePage/HPP4-Expertise'
import HPP5Industries from '../Components/HomePage/HPP5-Industries'
import HPP6Clients from '../Components/HomePage/HPP6-Clients'
import HPP9Reviews from '../Components/HomePage/HPP9-Reviews'
import HPP10Blogs from '../Components/HomePage/HPP10-Blogs'
import HPP11Contact from '../Components/HomePage/HPP11-Contact'
import { Parallax } from 'react-parallax'
import HPP8WebStories from '../Components/HomePage/HPP8-WebStories'
import HPP7WhyWe from '../Components/HomePage/HPP7-WhyWe'
import HPP7WhyWePhone from '../Components/HomePage/HPP7-WhyWePhone'
import { useLocation } from 'react-router-dom'


const HomePage = () => {

  const location = useLocation();


  useEffect(()=>{ 
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  },[location.pathname])

  
  return <div>
    <Parallax strength={1000}>
        <HPP1Hero/>
    </Parallax>
    <HPP2Services/>
    <HPP3Technologies/>
    <HPP4Expertise/>
    <HPP5Industries/>
    <HPP6Clients/>
    <HPP7WhyWe/>
    <HPP7WhyWePhone/>
    {/* <HPP8WebStories/> */}
    <HPP9Reviews/>
    <HPP10Blogs/>
    <HPP11Contact/>
  </div>
}

export default HomePage
