import React from 'react'
import style from "../../CSS/Careers/CAP3.module.css"
import "../../CSS/Root.css"
import dotsPattern from "../../Media/DotsSmall.svg"
import dotsPattern1 from "../../Media/dots.svg"


const bubbleData = [
    {
        id:1,
        content:"Stay ahead with evolving education. Learn and grow with us."
    },
    {
        id:2,
        content:"Empower colleagues to be brave, creative, entrepreneurial, agile, and responsible."
    },
    {
        id:3,
        content:"Happy, healthy, creative, efficient employees. Fun fosters communication, collaboration. We enable it."
    },
    {
        id:4,
        content:"Your work-life balance matters. Choose both. We support you."
    },
    {
        id:5,
        content:"Culture extends beyond work, embracing challenges to better the world."
    },
    {
        id:6,
        content:"Creating tomorrow's solutions, always seeking breakthroughs. Does this resonate?"
    },
]

const CAP3 = () => {
  return <div id={style.CAP3} className='flex'>
    <div id={style.layer1} className='flex'>
        {/* you can see there are 6 circles which have some content, here is the code for this! */}

        {
            bubbleData?.map((e)=>{
                return <div 
                    key={e?.id}
                    className={e?.id%2===1? `flex ${style.bubble} ${style.oddBubble}` : `flex ${style.bubble} ${style.evenBubble}` }
                >
                    <p>{e?.content}</p>
                </div>
            })
        }


        <div id={style.layer2} className='flex'>
            <div id={style.layer3} className='flex'>
                <h2>We are shaping the company of tomorrow</h2>
            </div>
        </div>
    </div>


    <img src={dotsPattern1} alt="" />
    <img src={dotsPattern1} alt="" />
    <img src={dotsPattern} alt="" />
  </div>
}

export default CAP3
