import React from 'react'
import style from "../../CSS/Services/SPP5ChooseUS.module.css"
import "../../CSS/Root.css"
import ServicesSlider from '../ChooseUSServicePageCarousel'

const SPP5ChooseUS = () => {
  return <div id={style.SPP5ChooseUS} className='flex'>
       <div>
        <h1>WHY CHOOSE US?</h1>
        <p>At Lanzz Tech, we stand out as your ideal partner for digital innovation and engineering excellence</p>
        <ServicesSlider/>
       </div>
  </div>
}

export default SPP5ChooseUS
