import React, { useEffect } from 'react'
import style from "../CSS/Error404Page.module.css"
import "../CSS/Root.css"
import vector404Error from "../Media/404Vector.svg"
import BasicButton from '../Components/BasicButton'
import dotsPatter404 from "../Media/dotsPattern404.png"
import { useLocation, useNavigate } from 'react-router-dom'

const Error404Page = () => {

    const navigate = useNavigate();

    const location = useLocation();

    const handleClick = ()=>{
      navigate("/")
    }


  useEffect(()=>{ 
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  },[location.pathname])

  return <div id={style.Error404Page} className='flex'>
    <img src={dotsPatter404} alt="" />
    <img src={vector404Error} alt="404 Error - Page Not Found!" />
    <div id={style.RightSection}>
        <h1>404</h1>
        <h2>Page Not Found</h2>
        <p>The page you are looking for doesn't exist. Please try searching for some other page, or return to the website's homepage to find what you're looking for.</p>
        <BasicButton
            title={"Go to Homepage"}
            HandleButnClick={handleClick}
        />
    </div>
    <img src={dotsPatter404} alt="" />
  </div>
}

export default Error404Page
