import React from 'react'
import style from "../../CSS/Home/HPP9Reviews.module.css"
import  "../../CSS/Root.css"
import VerticalCarousel from '../VerticalCarousel'
import appostopi from "../../Media/Apostopi.png"


const data = [
  {
    id:1,
    review:"Unlike numerous other vendors, Lanzz Tech prioritised our project and possesses a highly skilled team. They serve as a knowledgeable partner, facilitating easy communication, and their efforts have significantly streamlined and enhanced our processes.",
    position:"CEO",
    company:"Telecalling Company"
  },
  {
    id:2,
    review:"Lanzz Tech has been exceptionally communicative and a pleasure to collaborate with. They have consistently delivered on their promises and made me feel as though their team was a seamless extension of my own company. I eagerly anticipate completing the core stage and observing how our joint efforts are received in the marketplace.",
    position:"FOUNDER",
    company:"Fleet Management Company"
  },
  {
    id:3,
    review:"My experience with Lanzz Tech has been astounding. From design to development, Lanzz Tech has been there every step of the way holding our hand and bringing simplicity to what has developed into a cornerstone of our organization.",
    position:"CEO",
    company:"Financial Services Company"
  },
  
]

const HPP9Reviews = () => {
  return <div id={style.HPP9Reviews} className='flex'>
    <div id={style.HPP9ReviewsLeft} className='flex'>
        <div>
            <div className='flex'>
                <img src={appostopi} alt="" />
            </div>
            <h1>What our customers are saying</h1>
        </div>
    </div>
    <div id={style.HPP9ReviewsRight} className='flex'>
        <VerticalCarousel childrenStyle={style.carouselItem} data={data}/>
    </div>
  </div>
}

export default HPP9Reviews
