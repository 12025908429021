import React, { useState } from 'react'
import style from "../../CSS/HowWeWork/HWP4Contact.module.css"
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'


const HWP4Contact = () => {


    const [PhoneNuber,setPhoneNumber] = useState("");
    const [valid,setValid] = useState(true)

    const handleChange = (value)=>{
      setPhoneNumber(value);
      setValid(validation(value))
    }

    const validation = (num)=>{
        const PhoneNuberPattern = /^\d{10}$/;
        return PhoneNuberPattern.test(num);
    }




  return <div id={style.HWP4Contact} className='howWorkContact'>
      <h1> Craft Your Dream Project with us</h1>

      <form action="" id={style.prepForm}>

        <div>
          <div>
            <p>Full Name<span> * </span></p>
            <input type="text" placeholder='Your Name' />
          </div>
          <div>
            <p>Email ID<span> * </span></p>
            <input type="text" placeholder='Your Email ID' />
          </div>
          <div>
            <p>Company<span> * </span></p>
            <input type="text" placeholder='Your Company' />
          </div>
          <div className={style.div4}>
            <p>Mobile Number<span> * </span></p>
            <div className='flex'>
              
              <PhoneInput 
                country={'in'}
                placeholder='Your Mobile Number' 
                value={PhoneNuber}
                onChange={handleChange}
                id={style.phoneInput}

                inputProps={{
                  require: true,
                }}
                inputStyle={{
                  width:"96.2%",
                  borderRadius:"7px",
                  border:"0px",
                  boxShadow: "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px",
                }}
                buttonStyle={{
                    border:"0px",
                    borderRadius:"7px",
                    borderTopRightRadius:"0px",
                    borderBottomRightRadius:"0px",
                    backgroundColor:"white",  
                    borderRight:"0px"
                }}
              />

            </div>
            {/* {!valid && <p>please enter a valid 10-digit phone number!</p>} */}
           </div>
        </div>
        
        <div>
          <div >
            <p>Message<span> * </span></p>
            <textarea name="" id="" cols="30" rows="10" placeholder='How Can We Help You?'>

            </textarea>
          </div>
        </div>

        <input 
          type="submit" 
          name="" 
          id={style.SubmitInput} 
          value={"Submit"} 
        />

      </form>
  </div>
}

export default HWP4Contact
