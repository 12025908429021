import React, { useEffect } from 'react'
import BPP1Hero from '../Components/BlogsPage/BPP1-Hero'
import BPP2BlogsList from '../Components/BlogsPage/BPP2-BlogsList'
import BPP3Help from '../Components/BlogsPage/BPP3-Help'
import BPP4StayConnected from '../Components/BlogsPage/BPP4-StayConnected'
import { useLocation } from 'react-router-dom'

const BlogsPage = () => {

  const location = useLocation();


  useEffect(()=>{ 
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  },[location.pathname])

  return <div>
    <BPP1Hero/>
    <BPP2BlogsList/>
    <BPP3Help/>
    <BPP4StayConnected/>
  </div>
}

export default BlogsPage
