import React from 'react'
import style from "../../CSS/Home/HPP2Services.module.css"
import Heading from '../Heading'
import SearviceCard from '../SearviceCard'
import { TbDeviceImacCode } from "react-icons/tb";
import { Parallax } from 'react-parallax';
import { GiGearHammer } from "react-icons/gi";
import { LiaLaptopCodeSolid } from "react-icons/lia";
import { TiCloudStorageOutline } from "react-icons/ti";
import { VscAzureDevops } from "react-icons/vsc";
import { LuBrainCircuit, LuPenTool } from "react-icons/lu";
import { TbSettingsCode } from "react-icons/tb";
import { TbDeviceDesktopCode } from "react-icons/tb";
import { SiJfrogpipelines } from "react-icons/si";
import { GrAnalytics } from "react-icons/gr";




const ServiceData = [
  {
    id:1,
    title:"Software Product Development",
    description:"Crafting innovative software solutions tailored to meet specific client needs and market demands.",
    icon:<LiaLaptopCodeSolid/>,
    iconStyle:{
      color:"hotpink",
      backgroundColor:"rgba(255, 192, 203, 0.4)"
    }
  },
  {
    id:2,
    title:"Cloud Native Application Development",
    description:"Designing and building scalable and resilient applications optimized for cloud environments.",
    icon:<TiCloudStorageOutline/>,
    iconStyle:{
      color:"green",
      backgroundColor:"rgba(172, 255, 47, 0.4)"
    }
  },
  {
    id:3,
    title:"Cloud and DevOps",
    description:"Leveraging cloud technologies and DevOps practices to streamline development processes and enhance operational efficiency",
    icon:<VscAzureDevops/>,
    iconStyle:{
      color:"rgb(0, 183, 255)",
      backgroundColor:"rgba(135, 207, 235, 0.3)"
    }
  },
  {
    id:4,
    title:"Al/MI Development",
    description:"Creating intelligent systems powered by advanced algorithms to drive automation, insights, and decision-making.",
    icon:<LuBrainCircuit/>,
    iconStyle:{
      color:"red",
      backgroundColor:"rgba(255,0,0,0.2)"
    }
  },
  {
    id:5,
    title:"Backend Development",
    description:"Architecting robust and efficient server-side systems to support the functionality of software applications.",
    icon:<TbSettingsCode/>,
    iconStyle:{
      color:"purple",
      backgroundColor:"rgba(128, 0, 128, 0.2)"
    }
  },
  {
    id:6,
    title:"Frontend Development",
    description:"Developing intuitive and visually appealing user interfaces to enhance the user experience of software products.",
    icon:<TbDeviceDesktopCode/>,
    iconStyle:{
      color:"orangered",
      backgroundColor:"rgba(255, 68, 0, 0.2)"
    }
  },
  {
    id:7,
    title:"Data Engineering & Pipelines",
    description:"Constructing efficient data pipelines and infrastructure to process, transform, and manage large volumes of data.",
    icon:<SiJfrogpipelines/>,
    iconStyle:{
      color:"orange",
      backgroundColor:"rgba(255, 255, 0,0.4)"
    }
  },
  {
    id:8,
    title:"Data Analytics",
    description:"Analyzing and interpreting data to extract valuable insights that inform strategic business decisions.",
    icon:<GrAnalytics/>,
    iconStyle:{
      color:"teal",
      backgroundColor:"rgba(0,128,128,0.2)"
    }
  },
  {
    id:9,
    title:"UI/UX Consulting",
    description:"Providing expert guidance and design strategies to optimize user interface and user experience for software applications.",
    icon:<LuPenTool/>,
    iconStyle:{
      color:"gray",
      backgroundColor:"rgba(128, 128, 128, 0.2)"
    }
  },
]

const HPP2Services = () => {
  return     <div id={style.HPP2_Services} className='homeServicesSec'>
          <Heading 
              heading={"Our Tailored Software Development Solutions"} 
              badge={"Services"} 
              description={"As a reputable software development company specializing in software product engineering and IT solutions, we have earned the trust of our clients. With a team of over 50+ skilled developers, we design and develop systems that precisely align with business objectives, ensuring success."} 
          />

          <div id={style.serviceCardList}>
            {
              ServiceData?.map((e)=>{
                return <SearviceCard {...e} key={e?.id}/>
              })
            }
          </div>
        </div>
}

export default HPP2Services
