import React, { useEffect } from 'react'
import HWP1Hero from '../Components/HowWeWorkPage/HWP1-Hero'
import HWP2Work from '../Components/HowWeWorkPage/HWP2-Work'
import HWP3Preparation from '../Components/HowWeWorkPage/HWP3-Preparation'
import HWP4Contact from '../Components/HowWeWorkPage/HWP4-Contact'
import { useLocation } from 'react-router-dom'

const HowWeWorkPage = () => {

  const location = useLocation();


  useEffect(()=>{ 
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  },[location.pathname])

  return <div>
    <HWP1Hero/>
    <HWP2Work/>
    <HWP3Preparation/>
    <HWP4Contact/>
  </div>
}

export default HowWeWorkPage
