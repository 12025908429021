import React, { useState } from 'react';
import { Pagination } from 'antd';
const AntdPagination = ({pagiStyle,total}) => {
  const [current, setCurrent] = useState(3);
  const onChange = (page) => {
    console.log(page);
    setCurrent(page);
  };
  return <Pagination 
    className={pagiStyle}  
    current={current} 
    showSizeChanger={false}  
    onChange={onChange} 
    total={total} 
/>;
};
export default AntdPagination;