import React from 'react'
import style from "../CSS/BlogCard.module.css"

const BlogCard = ({id,title,featuredImage,category,desc}) => {
  return <div className={style.BlogCard} id={id} onClick={()=>console.log(11)}>
        <div 
            style={{
                backgroundImage:`url(${featuredImage})`
            }}
        >

        </div>
        <div>
            <h6>{category}</h6>
            <h3>{title}</h3>
            <p>{desc}</p>
            <span>Read More →</span>
            {/* <label>
                <p>January 31, 2024</p>
                <div></div>
                <p>09:12 AM</p>
            </label> */}
        </div>
  </div>
}

export default BlogCard
