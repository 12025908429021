import React from 'react';
import { ConfigProvider, Timeline } from 'antd';
import "../CSS/Root.css"



const TimelineCP = ({timeLineItems}) => {


  return <ConfigProvider
    theme={{
        components: {
            Timeline: {
                tailWidth:"0.15vw",
                tailColor:"white",
                dotBg:"transparent"
            },
        },
    }}
    >
    <Timeline
        // style={{margin:"20px"}}
        items={timeLineItems}
    />
</ConfigProvider>
  
  
  
};
export default TimelineCP;



// [
//     {
//       color: 'green',
//       children: 'Create a services site 2015-09-01',
//     },
//     {
//       color: 'green',
//       children: 'Create a services site 2015-09-01',
//     },
//     {
//       color: 'red',
//       children: (
//         <>
//           <p>Solve initial network problems 1</p>
//           <p>Solve initial network problems 2</p>
//           <p>Solve initial network problems 3 2015-09-01</p>
//         </>
//       ),
//     },
//     {
//       children: (
//         <>
//           <p>Technical testing 1</p>
//           <p>Technical testing 2</p>
//           <p>Technical testing 3 2015-09-01</p>
//         </>
//       ),
//     },
//     {
//       color: 'gray',
//       children: (
//         <>
//           <p>Technical testing 1</p>
//           <p>Technical testing 2</p>
//           <p>Technical testing 3 2015-09-01</p>
//         </>
//       ),
//     },
//     {
//       color: 'gray',
//       children: (
//         <>
//           <p>Technical testing 1</p>
//           <p>Technical testing 2</p>
//           <p>Technical testing 3 2015-09-01</p>
//         </>
//       ),
//     },
//     {
//       color: '#00CCFF',
//       dot: <SmileOutlined />,
//       children: <p>Custom color testing</p>,
//     },
//   ]