import React from 'react'
import style from "../../CSS/About/APP5Challenge.module.css"
import "../../CSS/Root.css"

const APP5Challenge = () => {
  return <div id={style.APP5Challenge} className='flex'>
    <div>
        <h1>Facing a challenge?</h1>
        <p>We will make sure you stay ahead of the curve.</p>
        <button className='flex' onClick={()=>document.querySelector(".contactAbout").scrollIntoView({behavior:"smooth"})}>Let's Talk</button>
    </div>
  </div>
}

export default APP5Challenge
