import React from 'react'
import style from "../../CSS/Home/HPP7WhyWePhone.module.css"
import "../../CSS/Root.css"
import Heading from '../Heading'



const Data = [
    {
        id:1,
        title:"Expertise and Experience",
        desc:"Extensive IT domain knowledge and years of experience guaranteeing top-quality solutions."
    },
    {
        id:2,
        title:"Cutting-Edge Technology",
        desc:"Staying ahead with the latest tools and methodologies for future-proof solutions."
    },
    {
        id:3,
        title:"Agility and Flexibility",
        desc:"Agile methodologies and adaptable project management ensure quick responses to changes."
    },
    {
        id:4,
        title:"Continuous Support and Maintenance",
        desc:"Providing ongoing support to keep systems operational, secure, and optimized."
    },
    {
        id:5,
        title:"Understanding Your Vision",
        desc:"Comprehensively grasping your project goals to align our efforts effectively."
    },
    {
        id:6,
        title:"Innovative Solutions Development",
        desc:"Crafting bespoke, market-leading solutions with cutting-edge technologies."
    },
]

const HPP7WhyWePhone = () => {
  return (
    <div id={style.HPP7WhyWe} className='flex'>
        <div className='flex'>
            <div id={style.HPP7WhyWeLeft}>
                <Heading
                    badge={"WHY WE?"}
                    heading={"We Focus On Deliever Innovative Solutions & Top Notch Products"}
                />
                <p>We don't claim to be the best, but we're confident that we can offer our clients a level of service and expertise that is unmatched by most other software development companies.</p>
            </div>
            <div id={style.HPP7WhyWeRight}>
                {
                    Data?.map((e)=>{
                        return <div key={e?.id} className={e?.id===6 ? `${style.WhyWeCard6}  ${style.WhyWeCardEven}  ${style.WhyWeCard}` : e?.id%2===0 ? `${style.WhyWeCardEven} ${style.WhyWeCard}` : e?.id===5 ? `${style.WhyWeCard5} ${style.WhyWeCard}` : style.WhyWeCard}> 
                            <div className='flex'>
                                <div className={`flex ${style.Icon}`}>
                                    {e?.id}
                                </div>
                                <h5>{e?.title}</h5>
                            </div>
                            <p>
                                {e?.desc}
                                <span>→</span>
                            </p>
                        </div>
                    })
                }
            </div>
        </div>
    </div>
  )
}

export default HPP7WhyWePhone;
