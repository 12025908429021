import React from 'react'
import style from "../../CSS/Home/HPP7WhyWe.module.css"
import Heading from '../Heading'
import "../../CSS/Root.css"
import mandala from "../../Media/mandla.png"

const Data = [
    {
        id:1,
        title:"Expertise and Experience",
        desc:"Extensive IT domain knowledge and years of experience guaranteeing top-quality solutions."
    },
    {
        id:2,
        title:"Cutting-Edge Technology",
        desc:"Staying ahead with the latest tools and methodologies for future-proof solutions."
    },
    {
        id:3,
        title:"Agility and Flexibility",
        desc:"Agile methodologies and adaptable project management ensure quick responses to changes."
    },
    {
        id:4,
        title:"Continuous Support and Maintenance",
        desc:"Providing ongoing support to keep systems operational, secure, and optimized."
    },
    {
        id:5,
        title:"Understanding Your Vision",
        desc:"Comprehensively grasping your project goals to align our efforts effectively."
    },
    {
        id:6,
        title:"Innovative Solutions Development",
        desc:"Crafting bespoke, market-leading solutions with cutting-edge technologies."
    },
]



const HPP7WhyWe = () => {
  return <div id={style.HPP7WhyWe}  className='flex'>
     <div>
        <Heading
            badge={"WHY WE?"}
            heading={"We Focus On Deliever Innovative Solutions & Top Notch Products"}
        />

        <div id={style.WhyWeCardList}>
            {
                Data?.map((e)=>{
                    return <div key={e?.id} className={style.WhyWeCard}>
                        <div className={style.Badge}>
                            {e?.id}.
                        </div>
                        <h4>{e?.title}</h4>
                        <p>{e?.desc}</p>
                        {
                            e?.id===1 ? <img src={mandala} alt="" className={style.IMG1} /> : e?.id===6 ? <img src={mandala} alt="" className={style.IMG2} />  : ""
                        }
                    </div>
                })
            }
            
        </div>
     </div>
  </div>
}

export default HPP7WhyWe
