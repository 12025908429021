import React, { useEffect } from 'react'
import SPP1Hero from '../Components/ServicesPage/SPP1-Hero'
import SPP2Services from '../Components/ServicesPage/SPP2-Services'
import SPP3Expertise from '../Components/ServicesPage/SPP3Expertise'
import SPP4Commitment from '../Components/ServicesPage/SPP4-Commitment'
import HPP11Contact from '../Components/HomePage/HPP11-Contact'
import SPP5ChooseUS from '../Components/ServicesPage/SPP5-ChooseUS'
import SPP6Contact from '../Components/ServicesPage/SPP6-Contact'
import { useLocation } from 'react-router-dom'

const ServicesPage = () => {


  const location = useLocation();


  useEffect(()=>{ 
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  },[location.pathname])

  return <>
    <SPP1Hero/>
    <SPP2Services/>
    <SPP3Expertise/>
    <SPP4Commitment/>
    <SPP5ChooseUS/>
    <SPP6Contact/>
  </>
}

export default ServicesPage
