import { Splide, SplideSlide } from "@splidejs/react-splide";
import { AutoScroll } from "@splidejs/splide-extension-auto-scroll";
import "@splidejs/splide/dist/css/splide.min.css";

const AutoCarousel = ({perPage,newClass,imgStyle,data})=>{
  return (
    <Splide 
      options={{
        type: "loop",
        // gap: "15vh",
        drag: false,
        arrows: false,
        pagination: false,
        perPage: 2,
        autoScroll: {
          pauseOnHover: false,
          pauseOnFocus: false,
          rewind: false,
          speed: 1
        },
      }}
      
      extensions={{ AutoScroll }}

      className={newClass}
    >
      {
        data?.map((e)=>{
          return <SplideSlide key={e?.id}>
            <img src={e?.logo} className={imgStyle} alt="Image1" />
          </SplideSlide>
        })
      }
      
    </Splide>
  );
}

export default AutoCarousel;