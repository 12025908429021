import React from 'react'
import style from "../../CSS/Home/HPP5Industries.module.css"
import "../../CSS/Root.css"
import Heading from '../Heading'
import TimelineCP from '../TimeLine'
import FintechIcon from "../../Media/FintechIcon.png"
import dotsPattern from "../../Media/dots.svg"
import dotsPatterSmall from "../../Media/DotsSmall.svg"
import { MdOutlineHealthAndSafety } from "react-icons/md";
import { TbZoomMoney } from "react-icons/tb";
import { RiGovernmentLine } from "react-icons/ri";
import { CiBullhorn } from "react-icons/ci";
import { FaNetworkWired } from "react-icons/fa";
import { BsCartCheck } from "react-icons/bs";



const dotIcon = (icon)=> <div className={`${style.timeLineIcon} flex`}>
        {/* <img src={FintechIcon} alt="Fintech" /> */}
        {icon}
    </div>


const timelineChildren = (title,desc)=> <div className={style.timelineChildren}>
    <h2>{title}</h2>
    {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pharetra aliquam condimentum mauris condimentum mi ut tellus sed. Montes, feugiat scelerisque semper egestas eu viverra fermentum eu vestibulum. Vehicula euismod diam nibh vel amet montes. Amet sit odio nunc, fermentum.</p> */}
</div>


const timeLineItems = [
    {
        dot:  dotIcon(<MdOutlineHealthAndSafety/>),
        children: timelineChildren("Healthcare"),
    },
    {
        dot:  dotIcon(<TbZoomMoney/>),
        children: timelineChildren("Fintech"),
    },
    {
        dot:  dotIcon(<RiGovernmentLine/>),
        children: timelineChildren("Government"),
    },
    {
        dot:  dotIcon(<CiBullhorn/>),
        children: timelineChildren("Advertising"),
    },
    {
        dot:  dotIcon(<FaNetworkWired/>),
        children: timelineChildren("Supply &  Chain"),
    },
    {
        dot:  dotIcon(<BsCartCheck/>),
        children: timelineChildren("Ecommerce"),
    },
]

const HPP5Industries = () => {

    


  return <div id={style.HPP5Industries} className='flex'>
    <div className='flex'>
        <div id={style.industriesTopLeft}>
            <Heading
                heading={"Transforming Industries With Cutting Edge Solutions"} 
                badge={"Industries"} 
            />
            <p>
                At Lanzz Tech, we leverage our expertise in product development to provide innovative solutions tailored to various industries.
                <br />
                <br />
                Our team of skilled professionals is committed to understanding the unique challenges and requirements of each industry, enabling us to deliver customized software solutions that drive growth and success.
                nyb-xpbu-smb
            </p>
        </div>
        <div id={style.industriesTopRight} className='flex'>
            <TimelineCP timeLineItems={timeLineItems}/>
        </div>
    </div>
        <img src={dotsPattern} className={style.dotsPattern1} alt="" />
        <img src={dotsPatterSmall} className={style.dotsPattern2} alt="" />
        {/* <img src={dotsPattern} className={style.dotsPattern3} alt="" />
        <img src={dotsPattern} className={style.dotsPattern4} alt="" /> */}
  </div>
}

export default HPP5Industries
