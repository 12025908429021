import React from 'react'
import style from "../../CSS/About/APP2About.module.css"
import "../../CSS/Root.css"
import Heading from '../Heading'


const APP2About = () => {
  return <div id={style.APP2About}>
        <div id={style.PhotoGrid}>
            <div className={style.div1}></div>
            <div className={style.div2}></div>
            <div className={style.div3}></div>
            <div className={style.div4}></div>
            <div className={style.div5}></div>
        </div>
        <div id={style.AboutContent}>
            <Heading
                badge={"ABOUT US"}
                heading={"ABOUT US"}
            />
            <p>
                Since 2023, our team of researchers, strategists, designers, and developers has helped organizations launch and implement hundreds of digital products turning their biggest uncertainties into opportunities for growth.
                <br />
                <br />
                Lanzz Tech is a young Product and Services development company based in India. We do everything related to web and mobile app development and are extremely passionate about building world-class products.
            </p>
        </div>
  </div>

}

export default APP2About
