import React from 'react'
import "../../CSS/Root.css"
import style from "../../CSS/Careers/CAP6Process.module.css"
import TimelineCareersProcess from '../Timeline-CareersProcess'
import careersSection6IMG from "../../Media/careerssection6BottomIMG.png"




const CAP6Process = () => {


    const dotTimeline = (i)=>{
        return <div className={`${style.timelineDot} flex ${i==="01"?style.activeDot:""}`}>{i}</div>
    }
    const childrenTimeline= (head,desc)=>{
        return <div className={style.timelineChildren}>
            <h3> {head} </h3>
            <p> {desc} </p>
        </div>
    }
    
    const items = [
        {
            dot:dotTimeline("01"),
            children:childrenTimeline("Find a role that suits you","Discover open positions and find your desired one in the Visioncraft website,  job listings or social media.")
        },
        {
            dot:dotTimeline("02"),
            children:childrenTimeline("Send your application","Some simple questions should be answered and your contact information is required ")
    
        },
        {
            dot:dotTimeline("03"),
            children:childrenTimeline("Receive your interview invite","We review all applications within 3 working days and send invitation to candidates.")
    
        },
        {
            dot:dotTimeline("04"),
            children:childrenTimeline("Choose an interview slot","You will have a friendly discution with the CEO and your  supervisor to talk about the work, life and etc.")
    
        },
        {
            dot:dotTimeline("05"),
            children:childrenTimeline("Preliminary Interview","Sometimes, we ask candidates to participate in some technical challenge that is designated to demonstrate candidates' proficiency.")
    
        },
        {
            dot:dotTimeline("06"),
            children:childrenTimeline("Meet the your teammates","To us is crucial to make sure all team members feel comfortable.  It is why we do try to have diverse but culturally fitted team members.")
    
        },
        {
            dot:dotTimeline("07"),
            children:childrenTimeline("Interview with our CEO","Your colleagues are waiting for you to say a warm welcome. ")
    
        },
        {
            dot:dotTimeline("🤝"),
            children:childrenTimeline("Start a new journey!")
    
        },
    ]

  return <div id={style.CAP6Process}>
    <div id={style.heading}>
        <h2>The Application Process </h2>
        <p>Joining us couldn't be easier! Check out our application process down below. If you have the talent we need, then we'll be meeting you soon!</p>
    </div>
    <div id={style.Timeline} className='flex'>
        <TimelineCareersProcess items={items}/>
    </div>
    <div id={style.BottomPart}>
        <img src={careersSection6IMG} alt="" />
    </div>
  </div>
}

export default CAP6Process
