import React from 'react';
import { InlineWidget, PopupWidget, useCalendlyEventListener } from 'react-calendly';
import "../CSS/Root.css"
import style from "../CSS/Calendely.module.css"

const CalendelySCH = ({idOk}) => {
  

useCalendlyEventListener({
    onEventScheduled: (e) => console.log(e.data.payload)
  });




  

  return (
    <div className="inline-widget"
      
    >
      {/* <InlineWidget 
        style={{
          height:"100%",
          width:"100%"
        }}
        id={style.CalendelySCH}
       url= "https://calendly.com/lanzztech/30min"
       /> */}


      <iframe
        src="https://calendly.com/lanzztech/30min"
        frameborder=""
        id={idOk}
        // style={{
        //   borderRadius:"2.5vh",
        //   width:"100%",
        //   height:"100%",
        // }}
      ></iframe>
      {/* <div className="calendly-inline-widget" data-url="https://calendly.com/lanzztech/30min" ></div> */}
    </div>
  );
};

export default CalendelySCH;
