import React from 'react'
import style from "../CSS/Heading.module.css"

const Heading = ({heading,badge,description,diff}) => {
  return <div className={style.Heading} style={{width:diff==="Expertise"?"85%":"100%"}}>
      {
        badge?<div className={badge==="Technologies"||badge==="Industries"||badge==="CONTACT US" ? style.badgeALT : style.badge}>
          {badge}
        </div>
        :
        ""
      }
      <h1 className={badge==="Technologies"||badge==="Industries" || badge==="CONTACT US"||diff==="Expertise" || badge==="WHY WE?" ? style.headingALT : style.heading}>{heading}</h1>
      <p style={{
        display:description?"flex":"none"
      }} className={ heading==="Our Client"? style.descriptionShort : badge==="Expertise" || badge==="WHY WE?" ?style.descriptionALT:style.description} >{description}</p>
  </div>
}

export default Heading
