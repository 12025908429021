import React from 'react'
import style from "../CSS/RecentBlogCardBP.module.css"

const RecentBlogCardBP = ({id,title,featuredImage}) => {
  return <div className={style.RecentBlogCardBP} key={id}>
    <div className={style.FeaturedIMG}>
        <img src={featuredImage} alt={title} />
    </div>
    <div className={style.BlogMetaData}>
        <p>{title}</p>
    </div>
  </div>
}

export default RecentBlogCardBP
