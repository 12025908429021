import React from 'react'
import style from "../../CSS/Home/HPP6Clients.module.css"
import Heading from '../Heading'
import AutoCarousel from '../AutoCarousel'
import teleforceLogo from "../../Media/teleforce.png"
import garudaLogo from "../../Media/garuda.png"


const data = [
  {
    id:1,
    logo:teleforceLogo
  },
  {
    id:2,
    logo:garudaLogo
  },
]

const HPP6Clients = () => {
  return <div id={style.HPP6Clients} className='flex'>
    <div className='flex'>
      <div id={style.HPP6ClientsHeading}>
          <Heading
            heading={"Our Client"}
            badge={"Clients"}
            description={"Several selected clients, who already believe in our service."}
          />
      </div>
      <div id={style.HPP6ClientsAutoCarousel}>
        <AutoCarousel data={data} perPage={4} imgStyle={style.IMG} newClass={style.perPage4}/>
        <AutoCarousel data={data} perPage={3} imgStyle={style.IMG} newClass={style.perPage3}/>
        <AutoCarousel data={data} perPage={2} imgStyle={style.IMG} newClass={style.perPage2}/>
        <AutoCarousel data={data} perPage={1} imgStyle={style.IMG} newClass={style.perPage1}/>
      </div>
    </div>
  </div>
}

export default HPP6Clients
