import React from 'react'
import style from "../../CSS/Home/HPP10Blogs.module.css"
import "../../CSS/Root.css"
import Heading from '../Heading'
import BlogsCarousel from '../BlogsCarousel'
import BasicButton from '../BasicButton'
import blog1 from "../../Media/blog1.png"
import blog2 from "../../Media/blog2.jpeg"
import blog3 from "../../Media/blog3.jpg"
import { useNavigate } from 'react-router-dom'




const data = [
    {
        id:1,
        title:"How Custom Software Solution can Revolutionize your Business",
        featuredImage:blog1,
        category:"Technology",
        desc:"Explore the transformative potential of custom software solutions and..."
    },
    {
        id:2,
        title:"Harnessing Cloud Technology: The Benefits of Cloud-Based Software",
        featuredImage:blog2,
        category:"Technology",
        desc:"Discover the transformative advantages..."
    },
    {
        id:3,
        title:"Innovating Tomorrow: Exploring the Future of Product Engineering",
        featuredImage:blog3,
        category:"Technology",
        desc:"Dive into the dynamic world of product engineering as..."
    }
]


const HPP10Blogs = () => {

    const navigate = useNavigate()



  return <div id={style.HPP10Blogs} className='flex'>
    <div>
        <div id={style.HPP10BlogsTop} className='flex'>
            <div id={style.HPP10BlogsTopLeft}>
                <Heading
                    badge={"Blogs"}
                    heading={"Our Blogs"}
                />
            </div>
            <div id={style.HPP10BlogsTopRight}>
                {/* <button onClick={()=>HandleNext()}>Next</button> */}
            </div>
        </div>
        <div id={style.HPP10BlogsMiddle} >
            <BlogsCarousel  perPage={4} data={data} newClass={style.perPage4}/>
            <BlogsCarousel  perPage={3} data={data} newClass={style.perPage3}/>
            <BlogsCarousel  perPage={2} data={data} newClass={style.perPage2}/>
            <BlogsCarousel  perPage={1} data={data} newClass={style.perPage1}/>
        </div>
        <div id={style.HPP10BlogsBottom} className='flex'>
            <BasicButton title={"Load More »"} />
            {/* HandleButnClick={()=>navigate("/blogs")} */}
        </div>
    </div>
  </div>
}

export default HPP10Blogs
