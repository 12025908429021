import React from 'react'
import style from "../../CSS/Careers/CAP2Heading.module.css"
import "../../CSS/Root.css"

const CAP2Heading = () => {
  return <div id={style.CAP2Heading} className='flex'>
    <h1>Are you passionate about making a difference? Let’s talk.</h1>
  </div>
}

export default CAP2Heading
