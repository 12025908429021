import React from 'react'
import style from "../../CSS/JobOpening/JOP2ApplicationForm.module.css"

const JOP2ApplicationForm = () => {
  return <div id={style.JOP2ApplicationForm} className='ApplyForm'>
    <h1>Job Application</h1>

    <div>
        <form action="" id={style.ApplicationFom} onSubmit={(e)=>e.preventDefault()}>
            
            <div id={style.top}>
                <div className={style.formItem}>
                    <p>Full Name <span>*</span></p>
                    <input type="text" placeholder='Enter your full name'/>
                </div>
                <div className={style.formItem}>
                    <p>Email ID <span>*</span></p>
                    <input type="text" placeholder='Enter your Email'/>
                </div>
                <div className={style.formItem}>
                    <p>Phone Number <span>*</span></p>
                    <input type="text" placeholder='Enter your Phone Number'/>
                </div>
            </div>


            <div className={`${style.uploadFile} ${style.formItem}`}>
                <p>Upload CV <span>*</span></p>
                <label >
                    <div className='flex'>
                        Drag and drop or browse files
                    </div>
                    <input type="file" name="" id="" autoComplete='off' />
                </label>
            </div>

            <div id={style.middle}>
                <div className={style.formItem}>
                    <p>State <span>*</span></p>
                    <input type="text" placeholder='Enter your State'/>
                </div>
                <div className={style.formItem}>
                    <p>City <span>*</span></p>
                    <input type="text" placeholder='Enter your City'/>
                </div>
                <div className={style.formItem}>
                    <p>Current CTC <span>*</span></p>
                    <input type="text" placeholder='Enter your Current CTC'/>
                </div>
                <div className={style.formItem}>
                    <p>Expected CTC <span>*</span></p>
                    <input type="text" placeholder='Enter your Expected CTC'/>
                </div>
                <div className={style.formItem}>
                    <p>Years of Experience <span>*</span></p>
                    <input type="text" placeholder='Enter your Relevant Experience'/>
                </div>
            </div>

            <div id={style.bottom}>
                <div className={style.formItem}>
                    <p>Primary skills <span>*</span></p>
                    <input type="text" placeholder='Enter your Primary skills'/>
                </div>
                <div className={style.formItem}>
                    <p>Secondary skills <span>*</span></p>
                    <input type="text" placeholder='Enter your Secondary skills'/>
                </div>
                <div className={style.formItem}>
                    <p>Why we should hire you ? <span>*</span></p>
                    <textarea placeholder='Write your message...'></textarea>
                </div>
            </div>

            <input type="submit" id={style.SubmitInput}/>
        </form>
    </div>
  </div>
}

export default JOP2ApplicationForm
