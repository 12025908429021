import React from 'react'
import "../../CSS/Root.css"
import style from "../../CSS/Careers/CAP8Contact.module.css"
import { HiOutlineMailOpen } from "react-icons/hi";
import { TbPhoneCall } from "react-icons/tb";



const CAP8Contact = () => {
  return <div id={style.CAP8Contact}>
    <div className='flex'> 

      <div className={`${style.content} flex`}>
        <div className={`${style.Icon} flex`}>
          <HiOutlineMailOpen/>
        </div>
        <h4>lanzz@youremail.com</h4>
      </div>

      <div id={style.divider}></div>

      <div className={`${style.content} flex`}>
        <div className={`${style.Icon} flex`}>
          <TbPhoneCall/>
        </div>
        <h4>+880 001 655 9985</h4>
      </div>

    </div>

    <div>

    </div>
  </div>
}

export default CAP8Contact
