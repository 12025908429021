import React from 'react'
import style from "../../CSS/Contact/CPP1Hero.module.css"
import "../../CSS/Root.css"

const CPP1Hero = () => {
  return <div id={style.CPP1Hero} className='flex'>
    <div>
      <p>GET IN TOUCH WITH US</p>
      <h1>We Are Ready To Assist You In 24x7</h1>
    </div>
  </div>
}

export default CPP1Hero
