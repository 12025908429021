import React from 'react'
import style from "../../CSS/Blogs/BPP1Hero.module.css"
import "../../CSS/Root.css"
import Heading from '../Heading'
import AppleVisionPro from "../../Media/AppleVisionPro.png"
import writer from "../../Media/Writer.png"




const BPP1Hero = () => {
  return <div id={style.BPP1Hero} className='flex'>
      <div>
        <div id={style.headingPart}>
            <Heading
              badge={"BLOGS"}
              heading={"Thoughts and words"}
            />
        </div>
        <div id={style.RecentBlog} className='flex'>
          <div id={style.RecentBlogFeaturedImage} className='flex'>
            <img src={AppleVisionPro} alt="AppleVisionPro" />
          </div>
          <div id={style.RecentBlogMetaData}>
            <div id={style.RBMDTop} className='flex'>
              <p>Technology</p>
              <div></div>
              <span>November 22, 2024</span>
            </div>
            <div id={style.RBMDMiddle}>
              <h2>Pitch termsheet backing validation focus release.</h2>
              <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Eligendi unde ex, alias saepe quidem repellat molestias, veritatis a quasi illo ipsam omnis impedit? Perferendis magni atque aperiam, beatae enim quia?</p>
            </div>
            <div id={style.RBMDBottom} className='flex'>
                <img src={writer} alt="" />
                <h5>Chandler Bing</h5>
            </div>
          </div>
        </div>
      </div>
  </div>
}

export default BPP1Hero
