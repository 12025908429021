import React from 'react'
import style from "../../CSS/About/APP4Mission.module.css"
import "../../CSS/Root.css"
import Heading from '../Heading'
import BasicButton from '../BasicButton'
import dotsPattern from "../../Media/dots.svg"


const APP4Mission = () => {
  return <div id={style.APP4Mission}>
    <div>
        <Heading
            badge={"MISSION"}
            heading={"Our Mission"}
            description={"To be the trusted digital partner and drive transformational outcomes for our customers, and empower our employees to achieve their full potential."}
        />
        <br />
        <BasicButton
            title={"Learn More"}
        />
        <img src={dotsPattern} alt="" />
    </div>
  </div>
}

export default APP4Mission
