import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/splide/dist/css/splide.min.css";
import '@splidejs/react-splide/css/skyblue'
import "../CSS/Root.css"
import BlogCard from "./BlogCard";






const BlogsCarousel = ({perPage,newClass,data})=>{

  return (
    <Splide 
      options={{
        type: "loop",
        gap: "10px",
        drag: false,
        arrows: false,
        pagination: false,
        perPage: perPage,
        perMove:1,
        autoplay:false,
        speed:0,
        // autoScroll: {
        //   pauseOnHover: false,
        //   pauseOnFocus: false,
        //   rewind: false,
        //   speed: 0.5
        // },
      }}
      className={newClass}


      // style={{
      //   width:"100%",
      //   height:"100%",
      //   // backgroundColor:"red"
      // }}
      
    //   extensions={{ AutoScroll }}
    >
      {
        data?.map((e)=>{
            return <SplideSlide  className="flex" style={{padding:"10px 0px"}} key={e?.id}>
                <BlogCard {...e} />
          </SplideSlide>
          
        })
      }
    </Splide>
  );
}

export default BlogsCarousel;