import React from 'react'
import style from "../../CSS/Contact/CPP2Help.module.css"
import "../../CSS/Root.css"
import helpImg from "../../Media/contactUSImage.jpg"
import Heading from '../Heading'
import { GrLocation } from 'react-icons/gr'
import { HiOutlineMailOpen } from 'react-icons/hi'
import { TbPhoneCall } from 'react-icons/tb'
import BasicButton from '../BasicButton'


const ContactData = [
  {
    id:1,
    icon:<GrLocation/>,
    title:"Visit Us :",
    content:"Pune, Maharashtra - 440019"
  },
  {
    id:2,
    icon:<HiOutlineMailOpen/>,
    title:"Drop Us :",
    content:"contact@lanzztech.com"
  },
  {
    id:3,
    icon:<TbPhoneCall/>,
    title:"Call Us :",
    content:"CALL: +91-9090908989"
  },
]




const CPP2Help = () => {
  return <div id={style.CPP2Help} className='flex'>
    <div id={style.CPP2HelpLeft} className='flex'>
        <img src={helpImg} alt="" />
    </div>
    <div id={style.CPP2HelpRight} className='flex'>
      <div>
        <Heading
          badge={"get in touch"}
          heading={"We are here to help you always..."}
          description={"At Lanzz Tech, assistance is just a message away. Whether you have questions, need guidance, or simply want to connect, we're dedicated to providing you with the support you need."}
        />
        <div id={style.contactList}>
          {
            ContactData?.map((e)=>{
              return <div className={`${style.contactCard} flex`} key={e?.id}>
                <div className='flex'>
                  {e?.icon}
                </div>
                <div>
                  <h4>{e?.title}</h4>
                  <p>{e?.content}</p>
                </div>
              </div>
            })
          }
        </div>
        <BasicButton
          title={"Get In Touch"}
          HandleButnClick={()=>document.querySelector(".calendelyBooking").scrollIntoView({behavior:"smooth"})}
        />
      </div>
    </div>
  </div> 
}

export default CPP2Help
